<template>
  <div class="download-btn-page">
    <div class="download-btn">
      下载试用
      <div class="download-right-btn">
        <div class="download-right-icon"></div>
      </div>
      <div class="version-warp">
        <div class="version-box">
          <div class="version-item" @click="download(1)">
            社区版（Community）
          </div>
          <!-- <div class="version-item" @click="download(3)">专业版（Studio）</div> -->
          <div
            class="version-item"
            onclick="window.open('https://wpa1.qq.com/yGWcFLfo?_type=wpa&qidian=true');"
          >
            企业版（Controller）
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { help } from "../../api/index";
export default {
  name: "downloadBtn",
  computed: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    download(type) {
      if (type == 1 || type == 3) {
        help.getUrl(type).then((res) => {
          window.open(res.data.data, "_target");
        });
        this.$router.push({ path: "/download" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.download-btn-page {
  .download-btn {
    width: 180px;
    height: 44px;
    background: #ff7f01;
    box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.04);
    border-radius: 28px;
    line-height: 44px;
    padding-left: 41px;
    box-sizing: border-box;
    font-size: 16px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    letter-spacing: 1px;
    position: relative;
    cursor: pointer;
    .download-right-btn {
      position: absolute;
      top: 0;
      right: 0;
      width: 44px;
      height: 44px;
      background: #fd9733;
      box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.04);
      border-radius: 0px 28px 28px 0px;
      cursor: pointer;
      .download-right-icon {
        border-top: 5px solid #ffffff;
        border-bottom: 5px solid transparent;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        height: 0;
        width: 0;
        margin: 0 auto;
        margin-top: 20px;
      }
    }
    a {
      color: #fff;
    }
    &:hover {
      color: #ffffff !important;
      background: #ff7f01 !important;
      a {
        color: #ff6600;
      }
    }
    .version-warp {
      position: absolute;
      top: 44px;
      left: 0;
      width: 180px;
      padding-top: 16px;
      box-sizing: border-box;
      .version-box {
        display: none;
        text-align: left;
        background: #ffffff;
        box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 16px 18px;
        box-sizing: border-box;
        // height: 150px;
        height: 110px;
        width: 246px;
        .version-item {
          width: 210px;
          height: 34px;
          border-radius: 4px;
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #333333;
          margin-bottom: 8px;
          line-height: 34px;
          padding-left: 6px;
        }
        .version-item:hover {
          background: #f6f6f6;
          color: #ff7f00;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
        }
      }
    }
  }
  .download-btn:hover,
  .version-warp:hover {
    .version-box {
      display: block;
    }
  }
}
</style>
