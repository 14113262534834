<template>
  <div class="about">
    <div class="banner-back">
      <div class="titles">关于我们</div>
      <!-- <div class="banner-back-left"></div>
      <div class="banner-back-right"></div> -->
    </div>

    <div class="div-content">
      <div class="text">公司简介</div>
      <div class="content" style="margin-top: 40px;">杭州未斯科技有限公司成立于2019年12月，通过践行帮助和推动企业数字化转型升级这一目标，不断研发新产品和新技术为现代企业提供综合的数字化产品及解决方案，帮助其全面提升工作效率，有效降低运营成本，在快速变化的市场环境下取得更好的发展和竞争优势。</div>
      <div class="content">我们的业务领域涵盖RPA机器人技术、低代码应用平台和人工智能等产品和技术，在消费互联网向产业互联网转型的大背景下，通过赋能传统企业，帮助他们整合公司资源，打通内外壁垒，驱动业务协同，实现公司整体效能的提升。我们的产品和技术可以广泛应用于国际物流、电商(含跨境)、新零售银行、保险、证券、政务、医药、能源、教育和生产制造等各个行业和领域。</div>
      <div class="content" style="margin-bottom: 80px;">未来，我们将致力于企业数智化转型升级，通过不断的产品研发和技术创新帮助其提升协作效率、降低运营成本，在新时代、新理念和新技术的驱动下探索更高效、更便捷的企业管理和经营方式，为他们创造更大的商业价值，并与中国数智化时代同行。</div>
    </div>

    <div class="about_dev">
      <div class="ad_title fCenter">发展历程</div>
      <div class="ad_con fCenter">
        <div class="adc_aside" style="margin-left: -150px;margin-right:30px;">
          <div class="item_show" style="align-items:flex-end;">
            <div class="logoImage5" style="width:145px;height:50px;"></div>
            <div class="is_time">2020年9月</div>
            <div class="is_desc">国际物流平台“e航无忧”第一版线下发布</div>
          </div>
          <div class="item_blank" style="width:100%;height:180px;"></div>
          <div class="item_show" style="align-items:flex-end;">
            <div class="logoImage1" style="width:87px;height:52px"></div>
            <div class="is_time">2021年8月</div>
            <div class="is_desc">RPA机器人产品套件UiFox正式上线</div>
          </div>
          <div class="item_blank" style="width:100%;height:180px;"></div>
          <div class="item_show" style="align-items:flex-end;">
            <div class="logoImage1" style="width:87px;height:52px"></div>
            <div class="is_time">2022年5月</div>
            <div class="is_desc">RPA 客户端 2.0 正式上线</div>
          </div>
        </div>
        <div class="adc_middle">
          <div class="adcl_out fCenter" style="background: #F3D7C9;">
            <div class="adcl_in" style="background: #FD6003;"></div>
          </div>
          <div class="adcl_line" style="background: #F3D7C9;"></div>

          <div class="adcl_out fCenter" style="background: #FFF0CD;">
            <div class="adcl_in" style="background: #FCC15B;"></div>
          </div>
          <div class="adcl_line" style="background: #FFF0CD;"></div>

          <div class="adcl_out fCenter" style="background: #FFE1E1;">
            <div class="adcl_in" style="background: #FD6003;"></div>
          </div>
          <div class="adcl_line" style="background: #F3D7C9;"></div>

          <div class="adcl_out fCenter" style="background: #FFEDE4;">
            <div class="adcl_in" style="background: #D98A66;"></div>
          </div>
          <div class="adcl_line" style="background: #FFEDE4;"></div>

          <div class="adcl_out fCenter" style="background: #F3D7C9;">
            <div class="adcl_in" style="background: #FD6003;"></div>
          </div>
          <div class="adcl_line" style="background: #FFEDE4;"></div>

          <div class="adcl_out fCenter" style="background: #F3D7C9;">
            <div class="adcl_in" style="background: #FD6003;"></div>
          </div>
        </div>
        <div class="adc_aside" style="margin-left: 30px;">
          <div class="item_blank" style="width:100%;height:150px;"></div>
          <div class="item_show">
            <div class="iamge_box fCenter" style="justify-content:flex-start;">
              <div class="logoImage2" style="width:174px;height:50px;margin-left:-20px;margin-right:20px;"></div>
              <div class="logoImage4" style="width:136px;height:50px"></div>
            </div>

            <div class="is_time">2021年5月</div>
            <div class="is_desc">获得“元璟资本”和“良仓基金’千万级天使</div>
          </div>
          <div class="item_blank" style="width:100%;height:200px;"></div>
          <div class="item_show">
            <div class="logoImage3" style="width:120px;height:50px"></div>
            <div class="is_time">2021年10月</div>
            <div class="is_desc">入选2021年度中国（杭州）人工智能板块政府重点扶持创新型项目</div>
          </div>
          <div class="item_blank" style="width:100%;height:190px;"></div>
          <div class="item_show">
            <div class="logoImage5" style="width:145px;height:50px"></div>
            <div class="is_time">2022年6月</div>
            <div class="is_desc">e航无忧 2.0正式上线</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 合作伙伴 -->
    <div class="work-div" style="height: auto;">
      <div class="text" style="margin-top: 130px;">我们的投资人</div>
      <div class="text-desc">获得“元璟资本”和“良米基金”千万级天使轮融资</div>
      <div class="invest_box fCenter">
        <div class="company1">
          <img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/about/zb1.png" />
        </div>
        <div class="company1" style="margin-left: 128px;">
          <img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/about/zb2.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "About",
  components: {
  },
  data() {
    return {
      showBack: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.about {
  .logoImage1 {
    background: url("../../assets/about/logo_1.png") no-repeat center/contain;
  }
  .logoImage2 {
    background: url("../../assets/about/logo_2.png") no-repeat center/contain;
  }
  .logoImage3 {
    background: url("../../assets/about/logo_3.png") no-repeat center/contain;
  }
  .logoImage4 {
    background: url("../../assets/about/logo_4.png") no-repeat center/contain;
  }
  .logoImage5 {
    background: url("../../assets/about/logo_5.png") no-repeat center/contain;
  }
  .item_show {
    width: 450px;
    height: auto;
    display: flex;
    flex-direction: column;
    .is_time {
      width: fit-content;
      height: 33px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #222222;
      line-height: 33px;
      letter-spacing: 3px;
      margin-top: 16px;
    }
    .is_desc {
      margin-top: 8px;
      width: fit-content;
      height: 24px;
      font-size: 14px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
    }
  }
  .line {
    width: 114px;
    height: 4px;
    border-radius: 4px;
    background: #fc6002;
    margin-top: 10px;
  }
  .about_dev {
    width: 100vw;
    height: 1200px;
    background: url("../../assets/about/time_bg.png") no-repeat center/cover;
    .ad_title {
      width: 100vw;
      height: 150px;
      font-size: 30px;
      font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
      font-weight: 800;
      color: #222222;
      line-height: 45px;
      letter-spacing: 3px;
    }
    .ad_con {
      width: 100%;
      height: 1050px;
      .adc_aside {
        width: 450px;
        height: 1050px;
        display: flex;
        flex-direction: column;
      }
      .adc_middle {
        width: 60px;
        height: 1050px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .adcl_line {
          height: 125px;
          width: 1px;
          margin-top: 2px;
          margin-bottom: 2px;
        }
        .adcl_out {
          width: 32px;
          height: 32px;
          border-radius: 16px;
        }
        .adcl_in {
          width: 8px;
          height: 8px;
          border-radius: 4px;
        }
      }
    }
  }
  .invest_box {
    width: 100%;
    height: 180px;
    margin-bottom: 60px;
    .company1 {
      width: 350px;
      height: 145px;
      background: #ffffff;
      box-shadow: 2px 2px 16px 6px rgba(0, 0, 0, 0.06);
      border-radius: 20px;
      overflow: hidden;
    }
  }

  .banner-back {
    height: 360px !important;
    background-image: url("../../assets/version2/about-img.png");
    .banner-back-left {
      background: none;
      background-image: url("../../assets/version2/about-img-left.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: top center;
      position: absolute;
      width: 371px;
      height: 306px;
      bottom: 0;
      left: 140px;
    }
    .banner-back-right {
      background: none;
      background-image: url("../../assets/version2/about-img-right.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: top center;
      position: absolute;
      width: 712px;
      height: 308px;
      bottom: 0;
      right: 120px;
    }
    .titles {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 42px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 800;
      color: #fff;
      padding-top: 170px;
      letter-spacing: 6px;
      // position: absolute;
      // bottom: 109px;
      // width: 100%;
      // text-align: center;
      // z-index: 2;
    }
  }

  .div-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    .text {
      font-size: 30px;
      font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
      font-weight: 800;
      color: #222222;
      line-height: 1.5;
      letter-spacing: 3px;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 60px;
    }
  }

  .content {
    width: 1092px;
    font-size: 14px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 400;
    color: #333;
    line-height: 1.5;
    letter-spacing: 2px;

    margin-bottom: 30px;
    text-indent: 2em;
  }

  .work-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    .text-desc {
      font-size: 20px;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: #222222;
      line-height: 1.5;
      margin: 32px 0;
    }
    .text {
      font-size: 30px;
      font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
      font-weight: 800;
      color: #222222;
      line-height: 1.5;
      font-weight: 800;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
