<template>
  <div
    :class="
      headerType == 'white'
        ? 'header-bar bg-orange white-header'
        : 'header-bar bg-orange'
    "
  >
    <div class="header-container">
      <div class="company-icon">
        <a href="#/index"
          ><img class="logo-img" src="../assets/version2/logo.png"
        /></a>
      </div>
      <ul class="navbar-ul">
        <el-menu
          :text-color="top > 0 ? '#FFFFFF' : '#FFFFFF'"
          active-text-color="#fc6002"
          :default-active="activeIndex"
          class="el-menu-demo"
          :class="top > 0 ? 'nav_style' : ''"
          mode="horizontal"
          router
        >
          <el-menu-item index="/index">首页</el-menu-item>
          <el-menu-item index="/product">产品中心</el-menu-item>
          <el-submenu index="4">
            <template slot="title">解决方案</template>
            <div class="menu-div" style="background: #fafafa">
              <el-menu-item
                index="/solutions/international_logistics"
                style="color: #000"
              >
                <img
                  style="margin-right: 10px"
                  width="15"
                  height="15"
                  src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/first/a.png"
                />国际物流
              </el-menu-item>
              <el-menu-item
                index="/solutions/local_e-commerce"
                style="color: #000"
              >
                <img
                  style="margin-right: 10px"
                  width="15"
                  height="15"
                  src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/first/b.png"
                />国内电商
              </el-menu-item>
              <el-menu-item
                index="/solutions/cross-border_e-commerce"
                style="color: #000"
              >
                <img
                  style="margin-right: 10px"
                  width="15"
                  height="15"
                  src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/first/c.png"
                />跨境电商
              </el-menu-item>
            </div>
            <!-- <el-submenu index="2-4">
              <template slot="title">选项4</template>
              <el-menu-item index="2-4-1">选项1</el-menu-item>
              <el-menu-item index="2-4-2">选项2</el-menu-item>
              <el-menu-item index="2-4-3">选项3</el-menu-item>
            </el-submenu> -->
          </el-submenu>
          <!-- <el-menu-item index="3" route="/version">版本</el-menu-item> -->
          <el-menu-item index="/successCase">成功案例</el-menu-item>
          <el-menu-item index="" @click="toRPAStore">RPA Store</el-menu-item>
          <!-- <el-menu-item index="" @click="toRPA">RPA社区</el-menu-item> -->
          <el-menu-item index="/about/about_us">关于我们</el-menu-item>
          <!-- <el-menu-item index="6" route="/help/Help">帮助中心</el-menu-item> -->
          <!-- <el-menu-item index="6" route="/product">客户案例</el-menu-item> -->
        </el-menu>
      </ul>
      <div class="right">
        <div class="btn1 flexCenter" v-if="headerType == 'white'">
          <img src="../assets/version2/phone_black.png" />
          <span>400-895-1888</span>
        </div>
        <div class="btn1 flexCenter" v-else>
          <img src="../assets/version2/phone_white.png" />
          <span>400-895-1888</span>
        </div>
        <div class="btn flexCenter">
          下载试用
          <div class="version-warp">
            <div class="version-box">
              <div class="version-item" @click="download(1)">
                社区版（Community）
              </div>
              <!-- <div class="version-item" @click="download(3)">
                专业版（Studio）
              </div> -->
              <div
                class="version-item"
                onclick="window.open('https://wpa1.qq.com/yGWcFLfo?_type=wpa&qidian=true');"
              >
                企业版（Controller）
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PopupCode :dialogType="dialog" @close="handleClose" />
  </div>
</template>

<script>
import PopupCode from "../components/PopupCode/PopupCode.vue";
import { help } from "../api/index";

export default {
  name: "main-navbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
    headerType: {
      default: "black",
      type: String,
    },
  },
  components: {
    PopupCode,
  },
  computed: {},
  data() {
    return {
      updateFlag: 1,
      activeIndex: "1",
      top: 0,
      dialog: false,
      navData: [
        {
          title: "首页",
          route: "/",
          child: false,
          active: true,
          name: "index",
        },
        {
          title: "产品",
          route: "/#/product",
          child: false,
          active: false,
          name: "product",
        },
        {
          title: "版本",
          route: "/#/version",
          child: false,
          active: false,
          name: "version",
        },
        {
          title: "解决方案",
          route: "/#/help/ClientHelp",
          child: false,
          active: false,
          name: "ClientHelp",
        },
        {
          title: "关于我们",
          route: "/#/about/about",
          child: false,
          active: false,
          name: "about",
        },
      ],
    };
  },
  created() {},
  mounted() {
    if (this.$route.path == "/") {
      this.activeIndex = "/index";
    } else {
      this.activeIndex = this.$route.path;
    }
    this.top = document.documentElement.scrollTop;
    document.body.onscroll = () => {
      this.top = document.documentElement.scrollTop;
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.activeIndex = to.path;
      //vm.$router.push({path:to.path}).catch(()=>{})
    });
  },
  methods: {
    // 跳转RPA社区
    toRPA() {
      window.open("http://bbs.uifox.com.cn");
    },
    toRPAStore() {
      window.open("https://www.eline56.com/rpaControl/rpaStore");
    },
    goContact() {
      this.$router.push({ path: "/contact/contact_us" });
    },
    changeRoute(item) {
      this.route.push({});
    },
    // 打开弹窗
    open() {
      if (this.$route.path == "/download") {
        help.getUrl().then((res) => {
          window.open(res.data.data, "_self");
        });
      } else {
        this.$router.push({ path: "/download" });
      }
    },
    // 关闭弹窗
    handleClose() {
      this.dialog = false;
    },
    download(type) {
      if (type == 1 || type == 3) {
        help.getUrl(type).then((res) => {
          window.open(res.data.data, "_target");
        });
        this.$router.push({ path: "/download" }).catch(() => {});
      }
    },
  },
  watch: {},
  filters: {},
};
</script>

<style lang="scss" scoped>
.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-dialog__body {
  padding: 0;
}
.el-dialog__header {
  padding: 0;
}

.header-bar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  padding-top: 20px;
  padding-bottom: 0.625rem;
  z-index: 1030;
  transition: 0.3s;

  .header-container {
    width: 1200px;
    height: 50px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;
    .company-icon {
      .logo-img {
        height: 36px;
      }
    }

    .navbar-ul {
      margin: 0;

      .el-menu-demo {
        background: none;
        border: none;

        .menu-div {
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          img {
            margin-right: 10px;
          }
        }

        /deep/ .el-menu-item {
          font-size: 14px;
          border: none;
          background: none;
          color: #fff;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        }
        /deep/ .el-menu-item:hover {
          color: #fc6002;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        }

        .el-submenu {
          font-size: 16px;
          border: none;
          background: none;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;

          // color: #fff !important;
          /deep/ .el-submenu__title {
            font-size: 14px;
            border: none;
            background: none;
            // color: #fff !important;
            &:hover {
              color: #fc6002;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            }
          }
          /deep/.el-submenu__icon-arrow {
            font-size: 16px;
            color: #000 !important;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          }
        }

        &.nav_style {
          .el-submenu {
            /deep/ .el-submenu__icon-arrow {
              color: #000 !important;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            }
          }
        }

        .is-opened {
          border: none !important;
          background: none !important;
          color: #fff;
        }

        .is-active {
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          border: none !important;
          background: none !important;
          color: #fc6002;
          font-weight: 500;
        }
      }

      .nav-item {
        display: inline-block;
        margin-left: 5px;
        padding: 0.5rem 0;

        .nav-link {
          text-transform: uppercase;
          font-size: 14px;
          padding: 0 0.7rem;
          line-height: 32px;
          text-decoration: none;
          transition: 0.3s;
          font-weight: 500;
          color: #fff;
          &:hover {
            color: #fc6002;
          }
        }
      }
      // .nav-item.active .nav-link:not(.btn),
      // .nav-item .nav-link:not(.btn):focus,
      // .nav-item .nav-link:not(.btn):hover,
      // .nav-item .nav-link:not(.btn):active {
      //   color: #FC6002 !important;
      //   border-bottom: 2px solid #FC6002;
      //   font-weight: bold;
      // }
    }

    .right {
      display: flex;
      width: fit-content;
      position: absolute;
      right: 0;
      top: -2px;
      .btn {
        width: 111px;
        height: 32px;
        background: #ff7f00;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
        font-size: 14px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #ffffff;
        line-height: 32px;
        letter-spacing: 1px;
        border-radius: 16px;
        position: relative;
        .version-warp {
          position: absolute;
          top: 32px;
          left: 0;
          padding-top: 16px;
          box-sizing: border-box;
          width: 111px;
          .version-box {
            display: none;
            text-align: left;
            background: #ffffff;
            box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            padding: 16px 18px;
            box-sizing: border-box;
            // height: 150px;
            height: 110px;
            width: 246px;

            .version-item {
              width: 210px;
              height: 34px;
              border-radius: 4px;
              font-size: 16px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #333333;
              margin-bottom: 8px;
              line-height: 34px;
              padding-left: 6px;
            }
            .version-item:hover {
              background: #f6f6f6;
              color: #ff7f00;
              font-family: SourceHanSansCN-Bold, SourceHanSansCN;
              font-weight: bold;
            }
          }
        }
      }
      .btn1 {
        margin-top: 10px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        border-radius: 2px;
        height: 32px;
        color: #ffffff;
        margin-right: 16px;
        img {
          margin-right: 10px;
        }
      }
      .btn:hover,
      .version-warp:hover {
        .version-box {
          display: block;
        }
      }
    }
  }
}

.bg-orange {
  padding-top: 0.625rem;
  //box-shadow: 0px 2px 8px 0px rgba(102, 102, 102, 0.1);
  background: #000000;
  .nav-link {
    color: #353535 !important;
    &:hover {
      color: #fc6002 !important;
    }
  }
  .activeNav {
    color: #fc6002 !important;
  }
  /deep/.el-icon-arrow-down:before {
    color: #ffffff;
  }
  /deep/.is-active .el-icon-arrow-down:before {
    color: #fc6002;
  }
}
.white-header {
  background: #f7f8fd;
  /deep/ .el-menu-item {
    color: #333333 !important;
  }
  /deep/ .el-menu-item:hover {
    color: #fc6002 !important;
  }
  .navbar-ul .el-menu-demo .is-active {
    color: #fc6002 !important;
  }
  /deep/ .el-submenu .el-submenu__title {
    color: #333333 !important;
  }
  /deep/ .el-icon-arrow-down:before {
    color: #333333 !important;
  }
  .header-container .right .btn1 {
    color: #333333 !important;
  }
  /deep/ .el-submenu.is-active .el-submenu__title {
    color: #fc6002 !important;
  }
  /deep/ .el-submenu.is-active .el-icon-arrow-down:before {
    color: #fc6002 !important;
  }
}
</style>
