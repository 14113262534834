<template>
  <div class="electricity-page">
    <div class="page-header">
      <div class="ph_box fCenter">
        <div class="pc_left fCenter">
          <div class="pcl_title">跨境电商解决方案</div>
          <div class="pcl_desc" style="height:120px;font-size:18px">最近这两年，我国跨境电商行业迎来了一个井喷的发展时期。同时，与跨境电商息息相关的软件服务业也在快速成长。RPA作为其中的代表之一，有效地帮助众多跨境商家解决了传统软件无法解决的问题，比如订单数据同步、跨境物流信息查询、财务自动对账等。</div>
          <DownloadBtn />
        </div>
        <div class="pc_right"></div>
      </div>
    </div>

    <div class="work-div" style="height: 1336px;">
      <div class="wow bounceInUp" data-wow-duration="1.5s" data-wow-delay="0.2s" data-wow-offset="200">
        <div class="text" style="margin: 40px 0 60px 0;">业务痛点分析</div>
        <div class="box">
          <CardCell url="ab_bg1" title="选品困难" content="对面海量的商品信息，跨境商家通过传统的人工筛选和简单系统比对很难筛选出合适的商品，错失了很多未来大卖的品类。" style="margin: 10px;" />
          <CardCell url="ab_bg2" title="运营工作效率低下" content="跨境电商玩法复杂，竞争激烈，依靠传统的“用人堆”的经营管理方式，必然导致企业内部的工作效率低下，基层员工苦不堪言。" style="margin: 10px;" />
        </div>
        <div class="box">
          <CardCell url="ab_bg3" title="订单转化率低，负面消息攀升" content="跨境业务的快速发展，客户服务跟不上，不仅容易流失重量级潜在客户，而且也会导致退款、退货甚至差评率的攀升。" style="margin: 10px;" />
          <CardCell url="ab_bg4" title="服务能力跟不上" content="很多中小型跨境商家没有专业的软件系统，面对众多客户就不得不花费更多的人力来进行分批处理，大大降低了服务工作效率。" style="margin: 10px;" />
        </div>
      </div>
      <div class="text" style="margin: 80px 0;">UiFox助力跨境电商卖家高效运营的同时有效提升服务的专业性</div>
      <div style="width: 1200px;display: flex;">
        <div class="zy">
          <div class="right">
            <img src="../../assets/plan/ab_left.png" />
          </div>
        </div>
        <div class="left">
          <div class="top">
            <div class="titles">数据抓取</div>
            <div class="content">在跨境电商店铺运营过程中，需要收集大量平台相关数据。UiFox帮助运营人员自动抓取各种数据，告别繁琐的数据收集过程。</div>
          </div>
          <div class="top">
            <div class="titles">订单处理</div>
            <div class="content">自动实时处理平台订单，将跟踪号上传至平台，UiFox可以做到平台订单实时反应，在解放人力的同时有效提升客户满意度。</div>
          </div>
          <div class="top">
            <div class="titles">差评监控</div>
            <div class="content">自动监控顾客对商品的差评，一旦店铺商品出现差评，UiFox第一时间抓取差评数据反馈给运营人员做listing页面优化，以减少商品的进一步差评。</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Button } from "@/components";
import CardCell from "../components/CardCell.vue"
import DownloadBtn from "../components/DownloadBtn.vue"
import { WOW } from "wowjs";

export default {
  name: "CrossElectricity",
  components: {
    [Button.name]: Button,
    CardCell,
    DownloadBtn,
  },
  data() {
    return {
      activeName: '1',
      dialog: false
     
    };
  },
  mounted() {
    var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 10,
      mobile: true,
      live: true
    })
    wow.init()
  },
  methods: {
    handleClose(){
      this.dialog = false
    },
		open(){
      this.$router.push({path:'/download'})
		},
  }
};
</script>

<style lang="scss" scoped>
.electricity-page {
  .page-header {
    height: 600px !important;
    background: url("../../assets/plan/s1_bg.png") no-repeat center/cover;
    margin-top: 60px;
    .ph_box {
      width: 1200px;
      height: 600px;
      margin: 0 auto;
      position: relative;
      z-index: 10;
      .pc_left {
        width: 600px;
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        .pcl_title {
          width: 288px;
          height: 36px;
          font-size: 36px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
          line-height: 54px;
        }
        .pcl_desc {
          width: 581px;
          height: 68px;
          font-size: 20px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #333333;
          line-height: 34px;
          letter-spacing: 1px;
          margin-top: 24px;
          margin-bottom: 32px;
        }
      }
      .pc_right {
        width: 474px;
        height: 404px;
        background: url("../../assets/plan/ab_right.png") no-repeat center/cover;
      }
    }
  }

  .work-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    margin-bottom: 60px;
    .zy {
      width: 584px;
      height: 427px;
      border-radius: 20px;
      overflow: hidden;
      margin-right: 30px;
      img {
        width: 584px;
        height: 427px;
      }
    }
    .left {
      .top {
        width: 580px;
        height: 142px;

        &:hover {
          background: #ffffff;
          box-shadow: 0px 2px 15px 0px #dddddd;
          border-radius: 20px;
        }
      }

      .titles {
        font-size: 20px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: blod;
        color: #353535;
        line-height: 22px;
        margin: 0 0 15px 40px;
        padding-top: 26px;
      }
      .content {
        width: 480px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #353535;
        line-height: 22px;
        margin: 0 0 15px 40px;
      }
    }

    .box {
      display: flex;
    }

    .text {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 30px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #353535;
      line-height: 45px;
      letter-spacing: 1px;
    }

    .box {
      display: flex;
    }
  }

  .h1-seo {
    font-size: 36px;
    // font-size: 50px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    line-height: 75px;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }
  .h3-seo {
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    line-height: 30px;
    margin-bottom: 40px;
    width: 850px;
  }
  .download-btn {
    width: 180px;
    height: 46px;
    background: linear-gradient(90deg, #fd621f 0%, #fb8a10 100%);
    box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.04);
    font-size: 16px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #ffffff;
    line-height: 46px;
    letter-spacing: 1px;
  }
}
</style>
