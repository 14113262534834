<template>
  <div>
    <el-submenu
      :index="item.id"
      popper-append-to-body
      v-if="item.childNode && item.childNode.length > 0"
    > 
      <template slot="title">
        <span>{{ item.name }}</span>
      </template>
      <SiderbarItem 
        v-for="(child,index) in item.childNode"
        :key="index"
        :item="child"
        @findContent="findContent"
      />
    </el-submenu>
    <el-menu-item v-else :index="item.id" @click="findContent(item.id)">
      <template slot="title">
        <span>{{ item.name }}</span>
      </template>
    </el-menu-item>

  </div>
</template>

<script>
// import SiderbarItem from './SiderbarItem'

export default {
  name: 'SiderbarItem',
  components: {
    // SiderbarItem
  },
  props: {
    item: Object
  },
  methods: {
    findContent(id){
      this.$emit('findContent', id)
    }
  }
  
}
</script>