<template>
  <div>
    <el-dialog title="企业办公自动化咨询" width="480px" :close-on-click-modal="false" :lock-scroll="false" :before-close="closeDialog" :visible.sync="visible">
      <div class="work-content">
        <div class="right">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-form-item label="姓名" prop="name" style="margin-top: 10px;">
              <el-input v-model="ruleForm.name" placeholder="请输入姓名" style="width: 440px;" />
            </el-form-item>
            <el-form-item label="电子邮箱">
              <el-input v-model="ruleForm.email" placeholder="请输入电子邮箱" style="width: 440px;" />
            </el-form-item>
            <el-form-item label="手机" prop="phone">
              <el-input v-model="ruleForm.phone" maxlength="11" placeholder="请输入手机号码" style="width: 440px;" />
            </el-form-item>
            <el-form-item label="公司名称" prop="companyName">
              <el-input v-model="ruleForm.companyName" placeholder="请输入公司名称" style="width: 440px;" />
            </el-form-item>
            <el-form-item label="留言" prop="note">
              <el-input type="textarea" v-model="ruleForm.note" placeholder="请输入留言" style="width: 440px;" />
            </el-form-item>
            <el-form-item style="width: 440px;">
              <div class="btn content-center" @click="submitForm('ruleForm')">提交</div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import { help } from '../api/index'

export default {
  name: 'ServiceScope',
  props: {
    visible: Boolean,
    sendData: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    var checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (!value) {
        return callback(new Error('邮箱不能为空'))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的邮箱格式'))
        }
      }, 100)
    }

    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|6|7|8|9][0-9]{9}$/
      if (!value) {
        return callback(new Error('电话号码不能为空'))
      }
      setTimeout(() => {
        // Number.isInteger是es6验证数字是否为整数的方法,但是我实际用的时候输入的数字总是识别成字符串
        // 所以我就在前面加了一个+实现隐式转换

        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('电话号码格式不正确'))
          }
        }
      }, 100)
    }
    return {
      ruleForm: {
        name: '',
        email: '',
        phone: '',
        companyName: '',
        profession: '',
        position: '',
        department: '',
        note: '',
      },
      rules: {
        name: [
          {required: true, message: '请输入姓名', trigger: 'blur'}
        ],
        email: [
          {required: true, message: '请输入正确的邮箱地址', trigger: 'blur'},
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
        email: [
          { required: true, message: '请输入邮箱地址', trigger: ['blur', 'change'] },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
        phone: [
          {required: true, message: '请输入电话', trigger: 'blur'},
          { validator: checkPhone, trigger: 'blur' }
        ],
        companyName: [
          {required: true, message: '请输入公司名称', trigger: 'blur'}
        ],
      },
		
    }
  },
  methods: {
    // 关闭
    closeDialog(){
      this.$emit('closeDialog')
    },
    submitForm(form){
      this.$refs[form].validate((valid) => {
        if(valid){
          help.getCrmAdd(this.ruleForm).then(res => {
            this.$confirm(res.data.data, '提示', {
              confirmButtonText: '确定',
              type: 'success'
            }).then(() => {
              this.closeDialog()
              help.getUrl().then(res => {
                window.open(res.data.data, '_self')
              })
            }).catch(() => {
                    
            });
            // this.$message({
            //   message: res.data.data,
            //   type: 'success'
            // })
          })
        } else {
          return false
        }
      })
    },
    
    
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__title {
  font-weight: 500;
}

.work-content {
  display: flex;
  .left {
    .titles {
      font-size: 24px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      line-height: 36px;
    }
    .content-div {
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      line-height: 48px;
    }
  }
  .right {
    // margin-left: 50px;
    .btn {
      height: 48px;
      background: linear-gradient(90deg, #fd621f 0%, #fb8a10 100%);
      border-radius: 4px;
      color: #fff;
      font-size: 18px;
    }
  }
}
</style>