import Vue from 'vue';
import App from './App.vue';
import router from './router';
import NowUiKit from './plugins/now-ui-kit';
// 公共样式
import './css/quill-editor.css'; // 公共css样式
import './css/public.scss'; // 公共css样式
import 'animate.css';
import 'wowjs/css/libs/animate.css'
// 导入element组件
import ElementUI from 'element-ui'

Vue.config.productionTip = false;

Vue.use(NowUiKit);
Vue.use(ElementUI, {
  size: 'mini',
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
