<template>
  <div class="logistics-page">
    <div class="page-header">
      <div class="ph_box fCenter">
        <div class="pc_left fCenter">
          <div class="pcl_title">国际物流解决方案</div>
          <div class="pcl_desc">航运智联网的大幕已开启，RPA机器人帮助国际物流企业持续 释放人力，降本增效，使其业务朝专业化和集约化方向发展。</div>
          <DownloadBtn />
        </div>
        <div class="pc_right"></div>
      </div>
    </div>

    <div style="height: 520px;"></div>

    <div class="work-div" style="height: 545px;background: #fff;">
      <div class="text" style="margin-bottom: 50px;">
        业务痛点分析
      </div>
      <div class="box awe_point">
        <CardLogistic 
          bg="bg1"
          title="信息化程度低"
          content="传统国际物流由于其特殊的行业属性，数智化发展缓慢，各个业务环节仍保留着传统的操作方式，日常工作中各类单据、票据、报表种类繁多，操作复杂，大多需要手工查找录入，不仅效率低下，而且投入巨大，劳心劳力。" />
        <CardLogistic title="存在大量机械、重复劳动" bg="bg2" content="国际物流业务涉及委托、运价、订舱、运输、仓储、报关、制单、跟踪等多个环节和流程，存在大量重复、机械化的手工操作，同样的数据信息需要在不同的系统和软件中重复录入，各个公司的操作人员每天疲于奔命。" />
        <CardLogistic title="人力成本居高不下" bg="bg3" content="社会经济的快速发展造成了各行各业人力成本居高不下，由于传统国际物流行业竞争激烈，利润率本身就不高（平均利润率10%~15%之间），一直高涨的人力成本逐渐稀释了企业利润，大多中小国际物流企业苦不堪言。" />
        <CardLogistic title="业务数据利用率低" bg="bg4" content="国际物流企业几十年的快速发展，积累了大量有价值的数据和行业经验，但由于数字化程度较低，企业没有很好的技术手段处理这些数据，再加上业务协作间的成本较高，这些常年积累的数据一直以来都没有得到有效利用。" />
      </div>
    </div>

    <div class="work-div" style="height: 536px;">
      <div class="text" style="margin-bottom: 40px;">UiFox为国际物流行业提供数智化产品及解决方案</div>
      <div class="first-div">
        <div class="bottom">
          <div class="content-text">引入UiFox数智化解决方案，能够不断提升内部工作效率，有效降低运营成本，帮助国际物流企业取得更大的竞争优势</div>
          <div class="content" style="margin-bottom:30px;"><img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/logistic/yes.png" style="margin-right: 8px; width: 24px;height: 24px;" />消灭重复劳动，定时自动获取、整理货物信息</div>
          <div class="content" style="margin-bottom:30px;"><img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/logistic/yes.png" style="margin-right: 8px; width: 24px;height: 24px;" />批量数据处理，自动填报提单信息</div>
          <div class="content" style="margin-bottom:30px;"><img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/logistic/yes.png" style="margin-right: 8px; width: 24px;height: 24px;" />跨系统数据同步，自动获取、填写数据</div>
          <div class="content" style="margin-bottom:30px;"><img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/logistic/yes.png" style="margin-right: 8px; width: 24px;height: 24px;" />全天自动运行，持续跟踪订舱、抢舱</div>
        </div>
        <div class="right">
          <img src="../../assets/plan/yeright.png" width="500px" height="253px" />
        </div>
      </div>
    </div>

    <div class="work-div" style="height: 1250px;background: #F7F7F7;">
      <div class="text" style="margin-bottom: 70px;">UiFox为国际物流企业提供了一系列开箱即用的业务场景</div>
      <div class="box">
        <crossCardCell 
          class="wow bounceInUp" 
          data-wow-duration="1.5s" 
          data-wow-delay="0.2s" 
          data-wow-offset="200" 
          url="is_logo1" 
          title1="设置定时时间" 
          title2="自动获取货物信息" 
          content="设置好轮询时间，自动登录到货物信息网站，查询货物跟踪信息，并将信息自动保存到Excel表格中或自有系统中。" style="margin: 10px;" />
        <crossCardCell 
          class="wow bounceInUp" 
          data-wow-duration="1.5s" 
          data-wow-delay="0.2s" data-wow-offset="200" url="is_logo2" 
          title1="单据信息" 
          title2="批量填报" content="自动读取整理好的标准单据信息，将关键信息自动填录到指定的系统中。" style="margin: 10px;" />
        <crossCardCell 
          class="wow bounceInUp" 
          data-wow-duration="1.5s" data-wow-delay="0.2s" data-wow-offset="200" 
          url="is_logo3" 
          title1="舱位信息" 
          title2="定时推送" content="自动登录船司网站，获取指定航线的余舱信息，自动获取时间、单价等核心信息，推送到钉钉群聊或者企业微信群聊中。" style="margin: 10px;" />
      </div>
      <div class="box">
        <crossCardCell class="wow bounceInUp" data-wow-duration="1.5s" 
        data-wow-delay="0.2s" data-wow-offset="200" url="is_logo4" 
        title1="AMS舱单" 
        title2="自动填报" content="自动读取标准AMS模板，自动登录到指定的AMS申报网站，智能录入申报信息，完成AMS申报动作。" style="margin: 10px;" />
        <crossCardCell class="wow bounceInUp" data-wow-duration="1.5s" 
        data-wow-delay="0.2s" data-wow-offset="200" url="is_logo5" title1="自动订舱" content="根据标准的委托单信息，自动登录对应的船司网站，完成特定的订舱操作。" style="margin: 10px;" />
        <crossCardCell class="wow bounceInUp" 
        data-wow-duration="1.5s" data-wow-delay="0.2s" 
        data-wow-offset="200" url="is_logo6" 
        title1="船期数据" 
        title2="自动跟踪" content="自动登录到港行信息网站，批量获取指定提单号对应的船名、航次、开截港时间、海放码放等数据信息，并将信息自动同步到Excel表格中或自有系统中。" style="margin: 10px;" />
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from "@/components";
import crossCardCell from "../components/crossCardCell.vue"
import CardLogistic from "../components/CardLogistic.vue"
import DownloadBtn from "../components/DownloadBtn.vue"
import { WOW } from "wowjs";

export default {
  name: "logistics",
  bodyClass: "index-page",
  components: {
    [Button.name]: Button,
    crossCardCell,
    CardLogistic,
    DownloadBtn,
  },
  data() {
    return {
      activeName: '1',
      dialog: false
    };
  },
  mounted() {
    var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 10,
      mobile: true,
      live: true
    })
    wow.init()
  },
  methods: {
    handleClose(){
      this.dialog = false
    },
		open(){
      this.$router.push({path:'/download'})
		},
  }
};
</script>

<style lang="scss" scoped>
.logistics-page {
  .awe_point {
    width: 1200px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .download-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    width: 180px;
    height: 46px;
    background: linear-gradient(90deg, #fd621f 0%, #fb8a10 100%);
    box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.04);
    font-size: 16px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #ffffff;
    line-height: 24px;
    letter-spacing: 1px;
  }

  .page-header {
    height: 600px !important;
    background: url('../../assets/plan/s1_bg.png') no-repeat center/cover;
    margin-top: 60px;
    .ph_box {
      width: 1200px;
      height: 600px;
      margin: 0 auto;
      position: relative;
      z-index: 10;
      .pc_left {
        width: 600px;
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        .pcl_title {
          width: 288px;
          height: 36px;
          font-size: 36px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
          line-height: 54px;
        }
        .pcl_desc {
          width: 581px;
          height: 68px;
          font-size: 20px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #333333;
          line-height: 34px;
          letter-spacing: 1px;
          margin-top: 24px;
          margin-bottom: 32px;
        }
      }
      .pc_right {
        width: 525px;
        height: 440px;
        background: url('../../assets/plan/s1_right.png') no-repeat center/cover;
      }
    }
  }

  .first-div {
    width: 1200px;
    height: 480px;
    background: #fff;
    justify-content: space-between;
    align-items: center;
    display: flex;

    .bottom {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .content-text {
        width: 570px;
        font-size: 20px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 400;
        color: #353535;
        line-height: 32px;
        margin-bottom: 50px;
      }
    }
  }

  .work-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    .tab-div {
      width: 1200px;
      /deep/ .el-tabs__nav-scroll {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      /deep/ .el-tabs__nav {
        width: 1000px;
      }
      // /deep/ .el-tabs__item {
      //   padding: 0 88px;
      // }
      /deep/ .el-tabs__item {
        padding: 0 128px;
      }
      /deep/ .el-tabs__item:hover {
        color: #fc6002;
      }
      /deep/ .is-active {
        color: #fc6002;
      }
      /deep/ .el-tabs__active-bar {
        background: #fc6002;
      }
      // /deep/ .el-tabs__active-bar {
      // width: 200px !important;
      // transform: translateX(16px) !important;
      // }
    }

    .text {
      font-size: 30px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #353535;
      line-height: 48px;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .box {
      display: flex;
    }
    .uifox-box {
      width: 360px;
      height: 444px;
      background: #fff;
      box-shadow: 10px 10px 40px rgba($color: #000000, $alpha: 0.1);
      padding: 40px;
      margin: 0 40px 40px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .box-title {
        font-size: 22px;
        font-weight: bold;
        margin: 44px 0;
        color: #222;
      }
      .box-content {
        font-size: 18px;
        color: #999;
        height: 158px;
        overflow: hidden;
      }
    }
  }

  .h1-seo {
    font-size: 36px;
    // font-size: 50px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    line-height: 75px;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }
  .h3-seo {
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    line-height: 30px;
    margin-bottom: 40px;
    width: 850px;
  }
}
</style>
