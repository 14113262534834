<template>
  <div class="contact-us-page">
    <div class="contact-left">
      <div class="contact-left-title">我们提供的服务</div>
      <div class="service-content">
        <div class="service-item">
          <img src="../../assets/version2/service-icon.png">
          <div class="service-desc">提供企业咨询服务，快速获取行业自动化解决方案</div>
        </div>
        <div class="service-item">
          <img src="../../assets/version2/service-icon.png">
          <div class="service-desc">企业级应用开发，强大技术支持，快速提升运营效率</div>
        </div>
        <div class="service-item">
          <img src="../../assets/version2/service-icon.png">
          <div class="service-desc">企业个性化需求定制，专业实施团队，支持私有化部署</div>
        </div>
      </div>
      <img src="../../assets/version2/contact-img1.png" class="contact-left-img">
    </div>
    <div class="contact-right">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入姓名" style="width: 560px;" :maxlength="50" />
        </el-form-item>
        <el-form-item label="电子邮箱">
          <el-input v-model="ruleForm.email" placeholder="请输入电子邮箱" style="width: 560px;" :maxlength="50" />
        </el-form-item>
        <el-form-item label="手机" prop="phone">
          <el-input v-model="ruleForm.phone" placeholder="请输入手机号码" style="width: 560px;" :maxlength="11" />
        </el-form-item>
        <el-form-item label="公司名称" prop="companyName">
          <el-input v-model="ruleForm.companyName" placeholder="请输入公司名称" style="width: 560px;" :maxlength="50" />
        </el-form-item>
        <el-form-item label="留言" prop="note">
          <el-input type="textarea" v-model="ruleForm.note" placeholder="请输入留言" style="width: 560px;" :maxlength="500" show-word-limit />
        </el-form-item>
        <el-form-item style="width: 560px;margin-top:32px">
          <div class="btn content-center" @click="submitForm('ruleForm')">提交</div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { help } from '../../api/index'
export default {
  name: "contactUs",
  computed: {},
  data() {
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|6|7|8|9][0-9]{9}$/
      if (!value) {
        return callback(new Error('电话号码不能为空'))
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('电话号码格式不正确'))
          }
        }
      }, 100)
    }
    return {
      ruleForm: {
        name: '',
        email: '',
        phone: '',
        companyName: '',
        profession: '',
        position: '',
        department: '',
        note: '',
      },
      rules: {
        name: [
          {required: true, message: '请输入姓名', trigger: 'blur'}
        ],
        email: [
          {required: true, message: '请输入正确的邮箱地址', trigger: 'blur'},
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
        phone: [
          {required: true, message: '请输入电话', trigger: 'blur'},
          { validator: checkPhone, trigger: 'blur' }
        ],
        companyName: [
          {required: true, message: '请输入公司名称', trigger: 'blur'}
        ],
      },
    }
  },
  created(){},
  mounted() {},
  methods: {
    submitForm(form){
      this.$refs[form].validate((valid) => {
        console.log(this.ruleForm);
        if(valid){
          help.getCrmAdd(this.ruleForm).then(res => {
            this.$confirm(res.data.data, '提示', {
              confirmButtonText: '确定',
              type: 'success'
            }).then(() => {
              //this.$router.push({path:'/'})
              this.ruleForm = {
                name: '',
                email: '',
                phone: '',
                companyName: '',
                profession: '',
                position: '',
                department: '',
                note: '',
              }
            }).catch(() => {
                    
            });
          })
        } else {
          return false
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.contact-us-page {
  width: 1200px;
  margin: 0 auto;
  .contact-left {
    float: left;
    margin-top: 6px;
    .contact-left-title {
      font-size: 24px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
      line-height: 36px;
      letter-spacing: 1px;
      margin-bottom: 32px;
    }
    .service-content {
      .service-item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        img {
          width: 16px;
          height: 16px;
        }
        .service-desc {
          margin-left: 8px;
          font-size: 18px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #333333;
          letter-spacing: 1px;
        }
      }
    }
    .contact-left-img {
      margin-top: 80px;
    }
  }
  .contact-right {
    float: left;
    margin-left: 90px;
    /deep/label {
      margin-bottom: 0;
    }
    /deep/.el-input--mini .el-input__inner {
      height: 48px;
      line-height: 48px;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
    }
    /deep/.el-textarea__inner {
      height: 96px;
    }
    .content-center {
      width: 560px;
      height: 56px;
      background: linear-gradient(90deg, #fd621f 0%, #fb8a10 100%);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
      border-radius: 28px;
      font-size: 16px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      line-height: 56px;
    }
    /deep/.el-form-item--mini.el-form-item {
      margin-bottom: 38px;
    }
    /deep/.el-form-item--mini .el-form-item__error {
      padding-top: 8px;
    }
  }
}
</style>
