<template>
  <div>
    <el-dialog :visible.sync="dialogType" width="30%" :before-close="handleClose" append-to-body>
      <img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/code.png" />
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'PopupCode',
  props: {
    dialogType: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    handleClose() {
      // this.dialogType = false;
      this.$emit('close')
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__body {
  padding: 0;
}
/deep/ .el-dialog {
  border-radius: 12px;
}
</style>
