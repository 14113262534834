<template>
  <div class="help-card">
		<div class="h-title">{{list.title}}</div>
		<div class="h-content">{{list.richText}}</div>
		<div class="h-desc">{{list.categorgName}}</div>
  </div>
</template>

<script>
export default {
  name: 'HelpCard',
	props:　{
		list:　{
			type: Object,
			// default: () => []
		}
	},
	data() {
		return {
		
		};
	},
	created(){
		console.log(this.list, 111111)
	},
	methods: {
	
	}
};
</script>

<style lang="scss" scoped>
.help-card{
	width: 100%;
	border-bottom: 1px solid #C2C2C2;

	font-size: 14px;
	font-family: Source Han Sans CN;
	color: #616161;
	.h-title {
		font-size: 16px;
		font-weight: bold;
		color: #000;
		margin: 20px 0 12px 0;
	}
	.h-desc {
		margin: 20px 0;
    font-weight: 400;
    color: #333333;
    font-size: 14px;
	}
}
</style>
