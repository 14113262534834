<template>
  <div class="first-page" id="first-page">
    <div class="banner-back">
      <!-- <div class="banner-back-left"></div>
      <div class="banner-back-right"></div> -->
    </div>
    <div style="height: 696px;"></div>

    <div class="page-header">
      <div class="container" style="margin-top: 0px;">
        <!-- <img src="../assets/version2/robot.png" class="robot"> -->
        <div style="width:567px;height:457px" class="robot"></div>
        <div class="content-left brand">
          <div class="c-l-text">
            <h1 class="h1-seo">打造您身边的数智化管家</h1>
            <div class="h3-seo">
              <p>UiFox致力于以科技创新助力产业智能</p>
              <p>实现智能化时代下的业务开拓</p>
            </div>
            <DownloadBtn></DownloadBtn>
          </div>
        </div>
      </div>
    </div>
    <div :class="stickScroll ? 'stick-bg stick-bg-scroll' : 'stick-bg'">
      <div class="stick-scroll-content">
        <div class="stick-scroll-content-item">
          <div class="stick-scroll-item-info">
            <div class="item-info-title" style="margin-top:74px">{{scrollObj.title}}</div>
            <div class="item-info-second-title">{{scrollObj.secondTitle}}</div>
            <div class="item-info-desc">{{scrollObj.desc}}</div>
          </div>
        </div>
      </div>
      <div class="stick-bg-img">
        <!-- <div class="stick-left-bg-img"></div>
        <div class="stick-right-bg-img"></div> -->
      </div>
    </div>
    <div class="stick-scroll-content">
      <div class="stick-scroll-content-item">
        <div class="stick-scroll-item-info" v-if="false">
          <div class="item-info-title" style="margin-top:74px">业务流程图</div>
          <div class="item-info-second-title">Transaction Flow Diagram</div>
          <div class="item-info-desc">流程图能够高效连接需求方（业务侧）和开发者（开发侧），像搭积木一样轻松的绘制流程图即可完成对整个业务流的梳理；作为双方对业务需求、交付验收的一种确认手段。</div>
        </div>
        <img src="../assets/version2/stick-scroll-img1.png">
      </div>
      <div class="stick-scroll-content-item">
        <div class="stick-scroll-item-info" v-if="false">
          <div class="item-info-title" style="margin-top:74px">独立应用机器人</div>
          <div class="item-info-second-title">Independent Application Robot</div>
          <div class="item-info-desc">我们支持将您的RPA应用场景打包成一个独立的应用程序，您可以为其设置属于自己的专属品牌LOGO和应用名，自由部署在任何您需要的电脑上运行。</div>
        </div>
        <img src="../assets/version2/stick-scroll-img2.png">
      </div>
      <div class="stick-scroll-content-item">
        <div class="stick-scroll-item-info" v-if="false">
          <div class="item-info-title" style="margin-top:74px">万物智能互联</div>
          <div class="item-info-second-title">Everything Is Interconnected</div>
          <div class="item-info-desc">我们同时支持在Windows端、Android端和iOS端进行流程自动化操控，只需要一个平台就能够让您玩转三端远程调度，无差别开发体验搭配个人专属服务器后台，让您不再受设备限制，享受真正的万物互联。</div>
        </div>
        <img src="../assets/version2/stick-scroll-img3.png">
      </div>
    </div>
    <div style="height:1900px"></div>
    <div class="home_compete">
      <div class="hc_box">
        <div class="hcb_title fCenter">核心竞争力</div>
        <div class="compete-list">
          <div class="compete-item-box">
            <div class="compete-item-one compete-item">
              <img src="../assets/version2/compete-img1.png">
              <div class="compete-title">资深研发团队</div>
              <div class="compete-content">由国内资深数智化产品和技术专家倾心打造，拥有十年以上产品研发经验，构建极致用户体验。</div>
            </div>
          </div>
          <div class="compete-item-box">
            <div class="compete-item-two compete-item">
              <img src="../assets/version2/compete-img2.png">
              <div class="compete-title">海量流程组件</div>
              <div class="compete-content">研发各行各业的海量流程组件提供用户自由选择，用户只需用鼠标简单的拖拽拼装即可完成想要的RPA机器人。</div>
            </div>
          </div>
          <div class="compete-item-box">
            <div class="compete-item-three compete-item">
              <img src="../assets/version2/compete-img3.png">
              <div class="compete-title">专业的服务保障</div>
              <div class="compete-content">专业的实施交付和技术支持团队，可深入客户现场进行业务调研、流程梳理，能提供全方位的产品、解决方案、技术支持和培训服务。</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ConsultDialog :visible.sync="visibleDialog" @closeDialog="closeDialog" />
    <PopupCode :dialogType="dialog" @close="handleClose" />
  </div>
</template>

<script>
import { Button } from "@/components";
import { WOW } from "wowjs";
import PopupCode from "../components/PopupCode/PopupCode.vue"
import ConsultDialog from "@/components/ConsultDialog"
import DownloadBtn from "./components/DownloadBtn"

export default {
  name: "index",
  bodyClass: "index-page",
  components: {
    [Button.name]: Button,
    PopupCode,
    ConsultDialog,
    DownloadBtn
  },
  computed: {},
  data() {
    return {
      showBack: false,
      dialog: false,
      activeName: '1',
      caseName: '1',
      visibleDialog: false,
      scrollObj:{
        title:'业务流程图',
        secondTitle:'Transaction Flow Diagram',
        desc:'流程图能够高效连接需求方（业务侧）和开发者（开发侧），像搭积木一样轻松的绘制流程图即可完成对整个业务流的梳理；作为双方对业务需求、交付验收的一种确认手段。'
      },
      stickScroll:false
    }
  },
  created(){
    if(this.$route.query.type == 'contact') {
      this.visibleDialog = true
    }
  },
  mounted() {
    var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 10,
      mobile: true,
      live: true
    })
    wow.init()
    // 向页面添加滚动事件
    window.addEventListener('scroll', this.handleScroll, true);
  },
  destroyed() { //离开这个界面之后，删除滚动事件，不然容易除bug
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    open(){
      this.$router.push({path:'/download'})
    },
    // 联系我们
    goContact(){
      this.$router.push({path:'/contact/contact_us'})
    },
    // 关闭弹窗
    handleClose(){
      this.dialog = false
    },
    closeDialog(){
      this.visibleDialog = false
    },
    handleScroll(){
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop >= 1300 && scrollTop < 2200) {
        this.scrollObj={
          title:'独立应用机器人',
          secondTitle:'Independent Application Robot',
          desc:'我们支持将您的RPA应用场景打包成一个独立的应用程序，您可以为其设置属于自己的专属品牌LOGO和应用名，自由部署在任何您需要的电脑上运行。'
        }
      } else if (scrollTop >= 2200){
        this.scrollObj={
          title:'万物智能互联',
          secondTitle:'Everything Is Interconnected',
          desc:'我们同时支持在Windows端、Android端和iOS端进行流程自动化操控，只需要一个平台就能够让您玩转三端远程调度，无差别开发体验搭配个人专属服务器后台，让您不再受设备限制，享受真正的万物互联。'
        }
      } else if(scrollTop <= 800){
        this.scrollObj={
          title:'业务流程图',
          secondTitle:'Transaction Flow Diagram',
          desc:'流程图能够高效连接需求方（业务侧）和开发者（开发侧），像搭积木一样轻松的绘制流程图即可完成对整个业务流的梳理；作为双方对业务需求、交付验收的一种确认手段。'
        }
      }
      if(scrollTop >= 2560){
        this.stickScroll = true;
      }else if(scrollTop < 2560){
        this.stickScroll = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1660px) {
  .banner-back-right {
    display: none !important;
  }
}
.first-page {
  .banner-back {
    height: 696px;
    width: 100%;
    background: #000000;
    display: block;
    background-image: url("../assets/version2/home-banner.png");
    margin-top: -30px;
    .banner-back-left {
      background: none;
      background-image: url("../assets/version2/home-banner-left.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: top center;
      float: left;
      width: 830px;
      height: 578px;
      margin: 118px 0 0 91px;
    }
    .banner-back-right {
      background: none;
      background-image: url("../assets/version2/home-banner-right.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: top center;
      float: right;
      width: 638px;
      height: 582px;
      margin: 118px 80px 0 0;
    }
  }
  .h1-seo {
    margin-bottom: 8px;
    margin-top: 100px;
    font-weight: 800 !important;
    font-weight: normal;
    color: #ffffff;
    font-size: 52px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
  }
  .h3-seo {
    margin: 20px 0 30px 0;
    width: 596px;
    font-size: 20px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
  }

  .title-text {
    font-size: 36px;
    color: #353535;
    margin-bottom: 19px;
    font-weight: bold;
    margin-top: 150px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    line-height: 50px;
    letter-spacing: 1px;
    flex-direction: column;
    .text-desc {
      margin-bottom: 18px;
      margin-top: 10px;

      font-size: 18px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #87969d;
      line-height: 27px;
    }
    img {
      width: 56px;
      height: 46px;
      left: -70px;
      top: -3px;
      position: absolute;
    }
  }
  .page-header {
    height: 696px;
    top: -30px;
    .container {
      padding-top: 145px;
      .robot {
        float: left;
      }
      .content-left {
        float: left;
        margin-left: 72px;
        p {
          margin-bottom: 0;
        }
      }
    }
  }
  .stick-bg {
    height: 900px;
    position: sticky;
    top: 72px;
    .stick-scroll-content {
      top: 0;
    }
    .stick-bg-img {
      width: 100%;
      height: 900px;
      background-image: url("../assets/version2/stick-bg.png");
      .stick-left-bg-img {
        float: left;
        width: 261px;
        height: 291px;
        margin: 140px 0 0 188px;
        background-image: url("../assets/version2/stick-left-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: top center;
      }
      .stick-right-bg-img {
        float: right;
        width: 742px;
        height: 904px;
        margin-right: 80px;
        background-image: url("../assets/version2/stick-right-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: top center;
      }
    }
  }
  .stick-bg-scroll {
    position: relative !important;
    top: 1900px;
  }
  .stick-scroll-content {
    position: absolute;
    z-index: 2;
    top: 720px;
    left: calc((100vw - 1200px) / 2);
    .stick-scroll-content-item {
      height: 900px;
      width: 1200px;
      text-align: center;
      margin: 0 auto;
      box-sizing: border-box;
      .stick-scroll-item-info {
        float: left;
        width: 520px;
        margin-right: 80px;
        text-align: left;
        .item-info-title {
          font-size: 44px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #171717;
        }
        .item-info-second-title {
          font-size: 28px;
          font-family: Arial-BoldMT, Arial;
          font-weight: normal;
          color: #171717;
          margin-bottom: 40px;
        }
        .item-info-desc {
          font-size: 18px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #212123;
        }
      }
      img {
        margin-left: 600px;
      }
    }
    .stick-scroll-content-item:nth-child(1) {
      padding-top: 180px;
    }
    .stick-scroll-content-item:nth-child(2) {
      padding-top: 225px;
    }
    .stick-scroll-content-item:nth-child(3) {
      padding-top: 220px;
    }
  }
}

.home_compete {
  background-image: url("../assets/version2/power_bg.png");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  // background-position: top center;
  height: 928px;
  position: relative;
  z-index: 2;
  .hc_box {
    width: 1260px;
    height: 928px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  .hcb_title {
    width: 100%;
    height: 52px;
    color: #171717;
    font-size: 52px;
    font-weight: bold;
    margin-top: 140px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  }
  .compete-list {
    .compete-item-box {
      width: 360px;
      height: 506px;
      float: left;
      margin-right: 60px;
    }
    .compete-item-box:nth-child(1) {
      margin-top: 50px;
      .compete-item {
        img {
          margin-top: 67px;
        }
        .compete-title {
          margin-top: 13px;
        }
      }
    }
    .compete-item-box:nth-child(2) {
      margin-top: 110px;
      .compete-item {
        img {
          margin-top: 70px;
        }
        .compete-title {
          margin-top: 44px;
        }
      }
    }
    .compete-item-box:nth-child(3) {
      margin-top: 70px;
      .compete-item {
        img {
          margin-top: 60px;
        }
        .compete-title {
          margin-top: 35px;
        }
      }
    }
    .compete-item {
      width: 360px;
      height: 486px;
      border-radius: 100px 0px 100px 0px;
      padding: 0 30px;
      box-sizing: border-box;
      text-align: center;
      position: relative;
      background: #ffffff;
      box-shadow: 0px 1px 30px 0px rgba(199, 199, 199, 0.2);
      .compete-title {
        font-size: 32px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #171717;
      }
      .compete-content {
        margin-top: 18px;
        font-size: 18px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #202020;
        text-align: left;
      }
    }
    .compete-item-box:hover {
      .compete-item {
        background: #fe6901;
        box-shadow: 5px 15px 20px 0px rgba(0, 0, 0, 0.15),
          0px 1px 30px 0px rgba(199, 199, 199, 0.2);
        animation: myMove 0.5s;
        -webkit-animation: myMove 0.5s; /* Safari and Chrome */
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
      }
      .compete-title {
        color: #ffffff;
      }
      .compete-content {
        color: #ffffff;
      }
    }
    @keyframes myMove {
      0% {
        top: 0px;
      }
      100% {
        top: -20px;
      }
    }

    @-webkit-keyframes myMove {
      0% {
        top: 0px;
      }
      100% {
        top: -20px;
      }
    }
  }
}
</style>
