<template>
  <div class="card-cell">
	<img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/product/11.png" v-if="url == 1" />
	<img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/product/22.png" v-if="url == 2" />
	<img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/product/33.png" v-if="url == 3" />
	<img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/product/44.png" v-if="url == 4" />
	<img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/product/55.png" v-if="url == 5" />
	<img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/product/66.png" v-if="url == 6" />
	<img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/product/77.png" v-if="url == 7" />
	<img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/product/88.png" v-if="url == 8" />
	<div class="right">
		<div class="titles">{{title}}</div>
		<div class="content">{{content}}</div>
	</div>
  </div>
</template>

<script>
// import { Carousel, CarouselItem } from 'element-ui';

export default {
  props: {
    url: String,
    title: String,
    content: String,
    content2: String,
    backContent: String,
    backTitle: String,
  },
  components: {
    // [Carousel.name]: Carousel,
    // [CarouselItem.name]: CarouselItem
  }
};
</script>

<style lang="scss" scoped>
.card-cell{
	width: 302px;
	height: 368px;
	background: #fff;
	display: flex;
	position: relative;
	border-radius: 20px;
	overflow: hidden;
	img {
		width: 302px;
		height: 368px;
	}
	.right {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: absolute;
		top: 293px;
		// bottom: -75px;
		width: 302px;
		height: 368px;
		background: #FC6000;
		opacity: 0.9;

		transition: All 0.4s ease-in-out;
		-webkit-transition: All 0.4s ease-in-out;
		-moz-transition: All 0.4s ease-in-out;
		-o-ttransition: All 0.4s ease-in-out;
		-ms-transition: All 0.4s ease-in-out;

		.titles {
			font-size: 22px;
			font-family: SourceHanSansCN-Bold, SourceHanSansCN;
			font-weight: bold;
			color: #FFFFFF;
			line-height: 33px;
			margin: 24px 0;
		}
		.content {
			width: 250px;
			font-size: 16px;
			font-family: SourceHanSansCN-Regular, SourceHanSansCN;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 32px;
			margin: 0 25px;
		}
	}
}

.right:hover {
	transform:translate(0,-293px);  
	-webkit-transform:translate(0,-293px);
	-moz-transform:translate(0,-293px);    
	-o-transform:translate(0,-293px);    
	-ms-transform:translate(0,-293px);
}
</style>
