import { axios } from '../request/index'
// let url = 'http://rpa.test.uifox-inc.com'
let url = process.env.VUE_APP_BASE_API
export default {
  // 获取type下所有类别
  getCategorgBot(params) {
    return axios.get(`${url}/services/categorgbot/secondlist/20001`, { params })
  },
  // 目录树
  getTree(data) {
    return axios.get(`${url}/services/help/tree/${data}`)
  },
  // 搜索
  searchFunc(params) {
    return axios.get(`${url}/services/help/search`, { params })
  },
  // 获取文档详情
  getDoc(data) {
    return axios.get(`${url}/services/help/doc/id/${data}`)
  },
  getDocAliasId(data) {
    return axios.get(`${url}/services/help/doc/aliasId/${data}`)
  },


  // 获取视频详情
  getVideo(data) {
    return axios.get(`${url}/services/help/video/id/${data}`)
  },

  // 获取 枚举
  getCrm() {
    return axios.get(`${url}/services/crm/enums`)
  },
  // 新增
  getCrmAdd(data) {
    return axios.post(`${url}/services/crm/add`, data)
  },

  // 获取url
  getUrl(data) {
    return axios.get(`${url}/services/public/version/latestUrl?clientType=${data}`)
  },

  // 获取历史版本
  getHistory() {
    return axios.get(`${url}/services/public/version/list`)
  },



  




  // /services/help/tree/{type}

}
