<template>
  <div class="pages-help-detail">
		<div class="detail-box">
			<div style="width: 20%;">
				<!-- <el-tree
					:data="data"
					:props="defaultProps"
					accordion
					@node-click="handleNodeClick">
				</el-tree> -->

				<el-input
					placeholder="输入关键字进行过滤"
					v-model="filterText">
				</el-input>

				<el-tree
					class="filter-tree"
					:data="data"
					:props="defaultProps"
					default-expand-all
					:filter-node-method="filterNode"
					ref="tree">
				</el-tree>
				
			</div>
			<div style="width: 80%;">222</div>
		</div>
		
     
  </div>
</template>

<script>
// import { Tabs, TabPane } from '@/components';

export default {
  name: 'profile',
  bodyClass: 'profile-page',
//   components: {
//     Tabs,
//     TabPane
//   }
	data() {
		return {
			data: [{
			label: '一级 1',
			children: [{
				label: '二级 1-1',
				children: [{
					label: '三级 1-1-1'
				}]
			}]
		}, {
			label: '一级 2',
			children: [{
				label: '二级 2-1',
				children: [{
					label: '三级 2-1-1'
				}]
			}, {
				label: '二级 2-2',
				children: [{
					label: '三级 2-2-1'
				}]
			}]
		}, {
			label: '一级 3',
			children: [{
				label: '二级 3-1',
				children: [{
					label: '三级 3-1-1'
				}]
			}, {
				label: '二级 3-2',
				children: [{
					label: '三级 3-2-1'
				}]
			}]
			}],
			defaultProps: {
				children: 'children',
				label: 'label'
			},
			filterText: ''
		};
	},
	watch: {
		filterText(val) {
			this.$refs.tree.filter(val);
		}
	},
	methods: {
		handleNodeClick(data) {
			console.log(data);
		},
		filterNode(value, data) {
			if (!value) return true;
			return data.label.indexOf(value) !== -1;
		}
	}
};
</script>

<style lang="scss" scoped>
.pages-help-detail{
	min-height: 100vh;
	background: greenyellow;

	.detail-box {
		width: 100%;
		display: flex;
	}
}
</style>
