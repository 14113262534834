<template>
  <div class="successCase-page">
    <div class="banner-back">
      <!-- <div class="banner-back-left"></div>
      <div class="banner-back-right"></div> -->
    </div>
    <div class="page-header">
      <div class="container" style="margin-top: 0px;">
        <div class="banner-title">给客户提供自动化解决方案</div>
        <div class="contact-us" @click="scrollContactUs">获取定制需求</div>
        <div class="case-list">
          <div class="case-item" v-for="(item,index) in caseList" :key="index">
            <img :src="item.img" class="case-img">
            <div class="case-name">{{item.name}}</div>
            <div class="case-tag">货代</div>
            <div class="case-desc">{{item.desc}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact-us-box">
      <div class="contact-us-container">
        <div class="contact-us-title">RPA定制需求模块</div>
        <ContactUs></ContactUs>
      </div>
    </div>
  </div>
</template>

<script>
import ContactUs from "./components/ContactUs.vue";
export default {
  name: "successCase",
  components: {
    ContactUs
  },
  computed: {},
  data() {
    return {
      caseList:[
        {
          img:require('@/assets/version2/case-img1.png'),
          name:'河北彤馨',
          desc:'UiFox针对客户关注的状态跟踪、箱货信息等问题给出解决方案，节省客户的查询时间，及时提供信息给到彤馨客户，提升服务品质，及客户满意度。'
        },
        {
          img:require('@/assets/version2/case-img2.png'),
          name:'上海万年青',
          desc:'客户使用了UiFox的软件机器人，将预配舱单流程均实现了自动化。为企业全流程的自动化，迈上坚实的一步。'
        },
        {
          img:require('@/assets/version2/case-img3.png'),
          name:'上海荣誉',
          desc:'UiFox助力客户免去人工每天去船公司网站中不断查询每票单子的简单重复的动作，让操作人员从低附加值的劳动中解放出来，去从事高附加值的工作，为企业降本增效。'
        },
        {
          img:require('@/assets/version2/case-img4.png'),
          name:'优尼科',
          desc:'客户使用UiFox提供的机器人，无 需任何操作，在对应的时间及时查 询到最新的价格，供商务人员参考、 比对。及时掌握一手最新的价格信 息，并为商务决策提供依据。'
        },
        {
          img:require('@/assets/version2/case-img5.png'),
          name:'明致宇海',
          desc:'UiFox提供一整套的订舱解决方案，解决客户在船公司网站录入一遍，在货代系统又录入一遍的繁重、重复的录入工作，并降低错误率，将订舱结果，通过邮件通知给操作，让操作检查，每日的 订舱完成情况。'
        },
        {
          img:require('@/assets/version2/case-img6.png'),
          name:'泛纳',
          desc:'流程化的工作，交由机器人来操作，可以极大的节省操作人员的工作量，降低错误率，给企业带来巨大的经济效益。'
        }
      ]
    }
  },
  methods: {
    scrollContactUs(){
      window.scrollTo(0, 1330)
    }
  },
};
</script>

<style lang="scss" scoped>
.successCase-page {
  .banner-back {
    width: 100%;
    height: 1408px;
    background: #000000;
    background-image: url("../assets/version2/success-case-bg.png");
    .banner-back-left {
      background-image: url("../assets/version2/success-case-bg-left.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: top center;
      float: left;
      width: 394px;
      height: 394px;
      margin: 240px 0 0 210px;
    }
    .banner-back-right {
      background-image: url("../assets/version2/success-case-bg-right.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: top center;
      float: right;
      width: 395px;
      height: 1253px;
      margin: 150px 120px 0 0;
    }
  }
  .page-header {
    position: absolute;
    top: 0;
    width: 100%;
    height: 1421px;
    z-index: 10;
    .container {
      padding-top: 152px;
      .banner-title {
        font-size: 56px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #ffffff;
        line-height: 84px;
        letter-spacing: 2px;
      }
      .contact-us {
        width: 180px;
        height: 44px;
        background: #ff7f01;
        box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.04);
        border-radius: 28px;
        font-size: 16px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #ffffff;
        line-height: 44px;
        letter-spacing: 1px;
        text-align: center;
        margin: 0 auto;
        margin-top: 48px;
        cursor: pointer;
      }
      .case-list {
        width: 1260px;
        margin: 0 auto;
        margin-top: 86px;
        .case-item {
          width: 360px;
          height: 426px;
          background: #ffffff;
          box-shadow: 0px 4px 6px 4px rgba(0, 0, 0, 0.04);
          border-radius: 32px;
          margin-right: 60px;
          margin-bottom: 60px;
          padding: 0 30px;
          box-sizing: border-box;
          float: left;
          .case-img {
            margin-top: 30px;
          }
          .case-name {
            font-size: 24px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #333333;
            letter-spacing: 2px;
            margin-top: 16px;
            margin-bottom: 12px;
          }
          .case-tag {
            width: 48px;
            height: 24px;
            background: #ffe9da;
            border-radius: 4px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ff6a00;
            line-height: 24px;
            text-align: center;
            margin: 0 auto;
          }
          .case-desc {
            font-size: 18px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #333333;
            line-height: 32px;
            letter-spacing: 1px;
            margin-top: 18px;
            text-align: left;
          }
        }
      }
    }
  }
  .contact-us-box {
    height: 920px;
    .contact-us-container {
      width: 1200px;
      margin: 0 auto;
    }
    .contact-us-title {
      font-size: 52px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
      line-height: 78px;
      letter-spacing: 2px;
      text-align: center;
      margin-top: 67px;
      margin-bottom: 65px;
    }
  }
}
</style>
