<template>
  <div>
    <div class="pages-help">
      <!-- 文档帮助 -->
      <div class="div-tab">
        <div class="video-box">
          <div
            style="
              width: 20%;
              border-right: 1px solid #e6e6e6;
              overflow: hidden;
              height: 100vh;
              position: relative;
            "
          >
            <div class="inner">
              <el-menu
                :collapse-transition="false"
                mode="vertical"
                :default-active="activeMenu"
              >
                <SidebarItem
                  @findContent="findContent"
                  v-for="(route, index) in menuData"
                  :key="index"
                  :item="route"
                />
              </el-menu>
              <!-- <el-menu
                :collapse-transition="false"
                mode="vertical"
                :default-active="activeMenu"
              >
                <template v-for="menu in menuData" >
                  <template v-if="menu.childNode.length > 0">
                    <el-submenu 
                      :key="menu.id"
                      :index="menu.id" 
                      :item="menu.id">
                        <template slot="title" >
                          <i class="el-icon-location"></i>
                          <span>{{ menu.name }}</span>
                        </template>
                        <el-submenu v-for="item in menu.childNode" :key="item.id" :index="item.id">
                          <template slot="title">{{item.name}}</template>
                          <el-menu-item v-for="val in item.childNode" :key="val.id" :index="val.id"  @click="getDocDetail(val.id)">{{val.name}}</el-menu-item>
                        </el-submenu>
                    </el-submenu>
                  </template>

                  <template v-else>
                    <el-menu-item :index="menu.id" :key="menu.id" @click="getDocDetail(menu.id)">
                      <i class="el-icon-location"></i>
                      <span slot="title">{{ menu.name }}</span>
                    </el-menu-item>
                  </template>
                </template>
              </el-menu> -->
            </div>
          </div>
          <div class="html-div" style="width: 80%">
            <!-- <div style="margin: 10px;" v-html="richtext"></div> -->
            <div class="ql-container ql-snow">
              <div class="ql-editor">
                <div style="margin: 10px" v-html="richtext"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { help } from "../../api/index";
import { Base64 } from "js-base64";
import SidebarItem from "./components/SidebarItem.vue";

export default {
  name: "ClientDoc",
  bodyClass: "profile-page",
  components: {
    SidebarItem,
  },
  data() {
    return {
      activeName: "1",
      menuData: [], // 树菜单
      activeMenu: "", // 激活菜单
      richtext: "", // 文档内容
    };
  },
  created() {
    this.getTreeData();
    console.log(this.$route.query);
    if (this.$route.query.aliasId) {
      setTimeout(() => {
        help.getDocAliasId(this.$route.query.aliasId).then((res) => {
          if (res.data.data) {
            this.activeMenu = res.data.data.documentId;
            this.richtext = Base64.decode(res.data.data.richtext);
          } else {
            this.richtext = "<p>暂无数据</p>";
          }
        });
      }, 300);
    }
  },
  methods: {
    // 获取文档内容
    findContent(id) {
      help.getDoc(id).then((res) => {
        if (res.data.data) {
          this.richtext = Base64.decode(res.data.data.richtext);
          let copyid = 0;
          $(document).ready(function () {
						// 所有pre模块增加复制按钮
            $("pre").each(function () {
              copyid++;
              $(this)
                .attr("data-copyid", copyid)
                .wrap('<div class="pre-wrapper"/>');
              $(this).parent().css("margin", $(this).css("margin"));
              $('<span class="copy-snippet"><i class="iconfont">&#xec7a;</i> 复制代码</span>')
                .insertAfter($(this))
                .data("copytarget", copyid);
            });

						// 点击复制代码
            $("body").on("click", ".copy-snippet", function (ev) {
              ev.preventDefault();
              let $copyButton = $(this);
              let $pre = $(document).find("pre[data-copyid=" + $copyButton.data("copytarget") + "]");
              if ($pre.length) {
                let textArea = document.createElement("textarea");

                textArea.style.position = "fixed";
                textArea.style.top = 0;
                textArea.style.left = 0;
								textArea.style.opacity = 0;
                textArea.value = $pre.html();

                document.body.appendChild(textArea);
                textArea.select();

                try {
                  document.execCommand("copy");
                  $copyButton.html('<span style="color: #999; cursor: default;">已复制</span>').prop("disabled", true);
                } catch (err) {
                  $copyButton
                    .text("复制失败")
                    .prop("disabled", true);
                }
                setTimeout(function () {
                  $copyButton.html('<i class="iconfont">&#xec7a;</i> 复制代码').prop("disabled", false);
                }, 3000);
              }
            });
          });
        } else {
          this.richtext = "<p>暂无数据</p>";
        }
      });
    },
    // 获取树
    getTreeData() {
      help.getTree(this.activeName).then((res) => {
        this.menuData = res.data.data.childNodes;
        if (res.data.data.childNodes && res.data.data.childNodes.length > 0) {
          if (
            res.data.data.childNodes[0].childNode &&
            res.data.data.childNodes[0].childNode.length > 0
          ) {
            if (
              res.data.data.childNodes[0].childNode[0].childNode &&
              res.data.data.childNodes[0].childNode[0].childNode.length > 0
            ) {
              this.activeMenu =
                res.data.data.childNodes[0].childNode[0].childNode[0].id;
            } else {
              this.activeMenu = res.data.data.childNodes[0].childNode[0].id;
            }
          } else {
            this.activeMenu = res.data.data.childNodes[0].id;
          }
        }
        this.findContent(this.activeMenu);
      });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.pages-help {
  font-size: 12px !important;
  min-width: 1019px;
  min-height: 100vh;
  background: #fff;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;

  /deep/ .el-menu-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  /deep/ .el-submenu__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .box {
    padding: 0 15%;
  }

  .content-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .div-tab {
    width: 100%;
    margin-bottom: 50px;

    .html-div {
      margin: 0 10px;
    }

    .tab-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .tab-content {
      width: 330px;
      height: 257px;
      flex-direction: column;
      background: #ffffff;
      border: 1px solid #bfbfbf;
      border-radius: 24px;
      margin-bottom: 48px;
      font-size: 14px;

      .div-title {
        font-size: 18px;
        color: #616161;
        margin: 5px 0;
        width: 300px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .div-text {
        color: #b5b5b5;
        margin: 5px 0;
        width: 300px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .div-more {
        color: #fc6002;
        margin: 5px 0;
        width: 300px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  /deep/ .el-tabs--border-card {
    border: none;
    box-shadow: none;
  }

  /deep/ .el-tabs__item.is-active {
    color: #fc6002 !important;
  }

  /deep/ .el-tabs__active-bar {
    background-color: #fc6002;
  }
  /deep/
    .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item:not(.is-disabled):hover {
    color: #fc6002;
  }

  /deep/ .el-tabs__item:hover {
    color: #fc6002;
  }
  /deep/ .el-input__inner {
    border-radius: 0;
  }

  /deep/ .el-menu-item.is-active {
    color: #fc6002;
  }

  /deep/ .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: none;
  }

  /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
    color: #fc6002;
    background: #fff;
    border: 1px solid #fc6002;
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #fc6002;
  }
  /deep/ .el-pagination.is-background .el-pager li {
    background-color: #fff;
    border: 1px solid #c2c2c2;
    color: #616161;
  }
  /deep/ .el-pagination.is-background {
    .btn-next {
      background-color: #fff;
      border: 1px solid #c2c2c2;
      color: #616161;
    }
    .btn-prev {
      background-color: #fff;
      border: 1px solid #c2c2c2;
      color: #616161;
    }
  }

  .video-box {
    display: flex;
    /deep/ .el-menu {
      border: 0;
    }
    .inner {
      position: absolute;
      left: 0;
      top: 0;
      right: -17px;
      bottom: 0;
      overflow-x: hidden;
      overflow-y: scroll;
    }

    .image-box {
      display: flex;
      flex-wrap: wrap;
    }

    .image-div {
      display: flex;
      flex-direction: column;
      width: 376px;
      margin: 10px 10px 10px 10px;

      .image-text {
        font-size: 14px;
        margin-top: 10px;
        color: #636363;
      }
    }
  }
}

/deep/ .ql-snow .ql-editor pre.ql-syntax {
  background: #fafafa;
  border: 1px solid #dedede;
  border-radius: 6px;
  color: #666;
  line-height: 16px;
  padding: 10px 15px;
}

/deep/ .pre-wrapper {
  position: relative;

  pre {
    padding-top: 25px;
    position: relative;
  }

  .copy-snippet {
    position: absolute;
    top: 10px;
    right: 14px;
    color: #666;
    cursor: pointer;

		.iconfont {
			font-size: 13px;
		}

    &:hover {
      color: #222;
    }
  }

  .pre-wrapper .pre-wrapper .copy-snippet:disabled {
    color: #555;
  }
}
</style>
