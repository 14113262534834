<template>
  <div class="customer-page">
    <!-- <div class="page-header" filter-color="orange" style="background-image:url('https://uifox-public.oss-cn-hangzhou.aliyuncs.com/uifox-files/mainpic/ux/banner.png'); background-repeat: no-repeat; background-size: auto 100%; background-position: top center;"> -->
    <div class="page-header">
      <parallax class="page-header-image">
        <!-- style="background-image:url('img/header.jpg')" -->
      </parallax>
      <div class="container" style="margin-top: 150px;">
        <div class="content-left brand">
          <div class="c-l-text">
            <h1 class="h1-seo">产品 · 赋能 · 领域 · 效果</h1>
            <h3 class="h3-seo">助力企业和行业生态开发者快速搭建机器人流<br />程和个性化的企业级应用</h3>
          </div>
        </div>
      </div>
    </div>

    <div class="banner-back">
      <!-- <div class="banner-back-left"></div> -->
      <!-- <div class="banner-back-right"></div> -->
      <!-- <div class="banner-back-center"></div> -->
    </div>
    <div style="height: 760px;">&nbsp;</div>

    <div class="work-div" style="height: 150px;">
      <div class="container card-box">
        <div class="card">
          <div class="card-imgs">
            <img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/icon6.png" />
          </div>
          <div class="card-rights">电商</div>
          <!-- <div class="card-right">
            <div class="card-text">
              <div class="card-titles">公司新闻</div>
            </div>
          </div> -->
        </div>

        <div class="card">
          <div class="card-imgs">
            <img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/icon2.png" />
          </div>
          <div class="card-rights">国际物流</div>
        </div>
        <div class="card">
          <div class="card-imgs">
            <img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/icon5.png" />
          </div>
          <div class="card-rights">新零售</div>
        </div>
        <div class="card">
          <div class="card-imgs">
            <img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/icon4.png" />
          </div>
          <div class="card-rights">跨境电商</div>
        </div>
        <!--        <div class="card">-->
        <!--          <div class="card-imgs">-->
        <!--            <img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/icon3.png" />  -->
        <!--          </div>-->
        <!--          <div class="card-rights">教育</div>-->
        <!--        </div>-->
        <!--        <div class="card">-->
        <!--          <div class="card-imgs">-->
        <!--            <img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/icon1.png" />  -->
        <!--          </div>-->
        <!--          <div class="card-rights">地产</div>-->
        <!--        </div>-->

      </div>
    </div>

    <div style="height: 930px;">
      <div class="content-box">
        <div class="content-div">
          <div>
            <img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/image_1.png" style="height: 402px;margin-bottom: 30px;" />
          </div>
          <div>
            <img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/icon1.png" style="width: 100px;height: 60px;" />
          </div>
          <div class="name" style="font-size: large">上海亚凯国际货物运输代理有限公司</div>
          <div class="desc">ONE智能订舱工具</div>
          <div class="btn">了解详情</div>
        </div>
        <div class="content-div" style="margin: 0 30px;">
          <div>
            <img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/image_1.png" style="height: 402px;margin-bottom: 30px;" />
          </div>
          <div>
            <img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/icon1.png" style="width: 100px;height: 60px;" />
          </div>
          <div class="name">上海飞瑞国际物流有限公司</div>
          <div class="desc">自动AMS填报</div>
          <div class="btn">了解详情</div>
        </div>
        <div class="content-div">
          <div>
            <img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/image_1.png" style="height: 402px;margin-bottom: 30px;" />
          </div>
          <div>
            <img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/icon1.png" style="width: 100px;height: 60px;" />
          </div>
          <div class="name">苏州贝宝电子商务有限公司</div>
          <div class="desc">1688异常订单数据采集</div>
          <div class="btn">了解详情</div>
        </div>
        <img class="img-left" src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/dian_bg.png" alt="">
        <img class="img-right" src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/dian_bg.png" alt="">
      </div>
    </div>

    <div class="download-divs" style="position: relative;">
      <div class="download">
        <div style="color: #fff;font-size: 36px;margin-bottom: 12px;font-weight: bold;">用科技，更好的服务我们的客户！</div>
        <div style="color: #fff;font-size: 18px;border: 1px solid #fff;border-radius: 4px;width: 205px;height: 48px;line-height: 48px;text-align: center;" @click="open">免费下载</div>
      </div>
    </div>

    <PopupCode :dialogType="dialog" @close="handleClose" />

  </div>
</template>

<script>
import { Button } from "@/components";
import PopupCode from "../components/PopupCode/PopupCode.vue"

export default {
  name: "index",
  bodyClass: "index-page",
  components: {
    [Button.name]: Button,
    PopupCode
  },
  computed: {},
  data() {
    return {
      showBack: false,
      dialog: false
    };
  },
  created() {},
  mounted() {
    
  },
  methods: {
    // 关闭弹窗
    handleClose(){
      this.dialog = false
    },
    open(){
      this.$router.push({path:'/download'})

      // this.dialog = true
    }
  },
};
</script>

<style lang="scss" scoped>
.customer-page {
  .page-header {
    height: 760px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  }
  .banner-back {
    height: 760px !important;
    // background-image:url('https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/banner_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: top center;
  }

  .work-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    .text-desc {
      font-size: 18px;
      color: #879690;
      margin-bottom: 18px;
    }
    .text {
      font-size: 36px;
      color: #353535;
      margin-bottom: 19px;
      font-weight: bold;
      img {
        width: 56px;
        height: 46px;
        margin-right: 9px;
      }
    }

    .card-box {
      display: flex;
      position: absolute;
      top: -60px;
      // left: 0;
      justify-content: center;
      z-index: 100;
      .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 210px;
        height: 120px;
        padding: 18px;
        margin-right: 16px;

        .card-imgs {
          width: 74px;
          height: 74px;
          img {
            width: 74px;
            height: 74px;
          }
        }
        .card-rights {
          // width: 396px;
          // width: 100%;
          font-size: 14px;
          color: #353535;
          font-weight: normal;
          margin-top: 5px;
        }
      }
      .card:last-child {
        margin-right: 0px;
      }
    }
  }

  .content-box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .img-left {
      position: absolute;
      bottom: -50px;
      right: 0;
      z-index: 1;
    }
    .img-right {
      position: absolute;
      left: 0;
      top: -50px;
    }
  }

  .content-div {
    width: 410px;
    height: 706px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 4px 6px 4px 0 rgba($color: #000000, $alpha: 0.06);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px;
    z-index: 20;

    .name {
      font-size: 24px;
      font-weight: bold;
      color: #5d5d5d;
      margin: 10px 0 20px 0;
    }
    .desc {
      font-size: 20px;
      color: #5d5d5d;
      margin-bottom: 20px;
    }
    .btn {
      width: 341px;
      height: 54px;
      background: linear-gradient(20deg, #fc6000 0%, #fb8736 100%);
      box-shadow: rgba($color: #000000, $alpha: 0.06);
    }
  }

  .download-divs {
    width: 100%;
    height: 620px;
    background: url("https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/banner4.jpg")
      no-repeat;
    background-size: 100% 100%;
    // padding: 0 80px;
    // margin: 0 auto;
    // justify-content: center;
    // align-items: center;
    // display: flex;
    .download {
      width: 1019;
      display: flex;
      color: #fff;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      bottom: 100px;
      right: 10%;
    }
  }

  .h1-seo {
    margin-bottom: 8px;
    font-weight: 800 !important;
    font-size: 36px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: normal;
    color: #353535;
  }
  .h3-seo {
    margin-top: 20px;
    font-size: 20px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: normal;
    color: #353535;
  }
}
</style>
