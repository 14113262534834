<template>
  <div class="LastDownload">
    <div class="download-divs" :class="bg == 1 ? 'footer_bg1' : bg == 2 ? 'footer_bg2' : bg == 3 ? 'footer_bg3' : bg == 4 ? 'footer_bg4' : ''">
      <div class="download">
        <!-- <div class="pos-background"></div> -->
        <div style="color: #fff;font-size: 36px;margin-bottom: 12px;font-weight: bold;">立即开启您的自动化之旅</div>
        <div class="btn-div" style="display: flex;">
          <div class="btn1" @click="open">免费下载</div>
          <div class="btn2" @click="goContact">联系我们</div>
        </div>
      </div>
    </div>
    <PopupCode :dialogType="dialog" @close="handleClose" />
  </div>
</template>

<script>
import PopupCode from "../../components/PopupCode/PopupCode.vue"

export default {
  components: {
    PopupCode
  },
  props: {
    bg: String,
    title: String,
    content: String,
    content2: String,
    backContent: String,
    backTitle: String,
  },
  data() {
    return {
      dialog: false,
     
    };
  },
	methods: {
    handleClose(){
      this.dialog = false
    },
		open(){
      this.$router.push({path:'/download'})

      // this.dialog = true
		},
    goContact(){
      this.$router.push({path:'/contact/contact_us'})
    },
	}
};
</script>

<style lang="scss" scoped>
.LastDownload {
  // margin-top: 249px;

  .download-divs {
    width: 100%;
    position: relative;
    background-size: 100% 100%;
    width: 100%;

    .download {
      width: 1200px;
      margin: 0 auto;
      padding: 320px 50px 0;
      display: flex;
      color: #fff;
      justify-content: center;
      flex-direction: column;
      position: relative;

      .pos-background {
        position: absolute;
        top: -293px;
        right: -120px;
        width: 673px;
        height: 621px;
        background: url("https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/version/%E5%BA%95%E9%83%A8%E4%B8%80.png")
          no-repeat;
        background-size: 100%;
      }

      .btn-div {
        display: flex;

        .btn1 {
          width: 158px;
          height: 46px;
          color: #fff;
          font-size: 18px;
          background: linear-gradient(90deg, #fd621f 0%, #fb8a10 100%);
          border-radius: 4px;
          line-height: 48px;
          text-align: center;
          margin-right: 20px;
          cursor: pointer;
          a {
            color: #fff;
          }
          &:hover {
            color: #ff6600;
            background: #fff;
            a {
              color: #ff6600;
            }
          }
        }
        .btn2 {
          color: #fff;
          font-size: 18px;
          border: 1px solid #fff;
          border-radius: 4px;
          width: 158px;
          height: 46px;
          line-height: 46px;
          text-align: center;
          cursor: pointer;
          &:hover {
            color: #fff;
            background: #ff6600;
            border: none;
          }
        }
      }
    }
  }

  .footer_bg1 {
    height: 267px;
    background: url("https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/version/%E5%BA%95%E9%83%A8%E4%BA%8C.png")
      no-repeat;
    background-size: 120% 100%;
  }

  .footer_bg2 {
    height: 526px;
    background: url("https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/footer/footbg_2.png")
      no-repeat;
    background-size: 100% 100%;
  }

  .footer_bg3 {
    height: 526px;
    background: url("https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/footer/footbg_3.png")
      no-repeat;
    background-size: 100% 100%;
  }

  .footer_bg4 {
    height: 526px;
    background: url("https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/footer/footbg_4.png")
      no-repeat;
    background-size: 100% 100%;
  }
}
</style>
