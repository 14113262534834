<template>
  <div id="app" style="position: relative;">
    <router-view name="header" />
    <div class="wrapper">
      <router-view />
    </div>
    <router-view name="footer" />
    <div class="app-logo">
      <div style="position: relative;">
        <!-- <img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/first/logo.png" onclick="window.open('https://wpa1.qq.com/yGWcFLfo?_type=wpa&qidian=true');" width="100" height="100" /> -->
        <div class="fix-icons">
          <div class="fix-icon-item">
            <div class="fix-icon-one fix-icon"></div>
            <div class="hover-content">
              <div class="hover-item" onclick="window.open('https://wpa1.qq.com/yGWcFLfo?_type=wpa&qidian=true');">
                <img src="./assets/version2/hover-icon1.png">
                <span>客服QQ</span>
              </div>
              <div class="hover-item">
                <img src="./assets/version2/hover-icon1.png">
                <div>QQ群：675222270</div>
              </div>
              <div class="hover-item" style="height:162px">
                <img src="./assets/version2/qq.png" class="qq-img">
              </div>
            </div>
          </div>
          <div class="fix-icon-item">
            <div class="fix-icon-two fix-icon"></div>
            <div class="hover-content">
              <div class="hover-item" onclick="window.open('https://wpa1.qq.com/yGWcFLfo?_type=wpa&qidian=true');">
                <img src="./assets/version2/hover-icon2.png">
                <span>定制机器人</span>
              </div>
            </div>
          </div>
          <div class="fix-icon-item">
            <div class="fix-icon-three fix-icon"></div>
            <div class="hover-content">
              <div class="hover-item" @click="goHelp">
                <img src="./assets/version2/hover-icon3.png">
                <span>帮助中心</span>
              </div>
            </div>
          </div>
        </div>
        <div id="box">
          <div style="display: flex;justify-content: space-between;">
            <div style="margin: 20px 0px 0 20px;">
              <img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/first/wxgzh.png" width="225" height="225" />
              <div class="content-center text">微信扫码关注公众号</div>
            </div>
            <div style="margin: 20px 20px 0 20px;">
              <img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/first/ddCode.png" width="225" height="225" />
              <div class="content-center text">钉钉扫码加群</div>
            </div>
          </div>
          <div class="content-center phone">热线电话：<span>17326085244</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    goHelp(){
      this.$router.push({path:'/help/Help'})
    }
  }
};
</script>

<style lang="scss" scoped>
.app-logo {
  position: fixed;
  bottom: 70px;
  right: 70px;
  cursor: pointer;
  z-index: 9999;
}

#box {
  width: 400px;
  height: 260px;
  background: #fff;
  position: absolute;
  left: -400px;
  top: -180px;
  display: none;
  border-radius: 8px;
  z-index: 10000;
  box-shadow: 0px 2px 18px 7px rgba(0, 0, 0, 0.14);

  img {
    width: 170px;
    height: 170px;
  }

  .text {
    font-size: 16px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #222222;
    line-height: 30px;
    margin-top: 10px;
  }
}
.phone {
  font-size: 14px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: #222222;
  line-height: 24px;
  span {
    color: #fd651e;
  }
}
// .app-logo:hover {
//   #box {
//     display: inline-block;
//     z-index: 10000;
//   }

// }
.fix-icons {
  position: fixed;
  right: 32px;
  top: 50%;
  .fix-icon-item {
    padding-left: 16px;
    position: relative;
    .hover-content {
      width: 210px;
      background: #ffffff;
      border-radius: 10px;
      padding: 16px 10px;
      box-sizing: border-box;
      position: absolute;
      right: 72px;
      top: 8px;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      display: none;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
      .hover-item {
        width: 190px;
        height: 30px;
        border-radius: 4px;
        line-height: 30px;
        padding-left: 14px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        img {
          margin-right: 8px;
          width: 12px;
          height: 12px;
        }
        .qq-img {
          width: 162px;
          height: 162px;
        }
      }
      .hover-item:hover {
        background: #f9f9f9;
      }
    }
  }
  .fix-icon-item:hover {
    .hover-content {
      display: block;
    }
  }
  .fix-icon {
    width: 70px;
    height: 70px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: top center;
  }
  .fix-icon-one {
    background-image: url("./assets/version2/fix-icon1.png");
  }
  .fix-icon-one:hover {
    background-image: url("./assets/version2/fix-icon1-hover.png");
  }
  .fix-icon-two {
    background-image: url("./assets/version2/fix-icon2.png");
  }
  .fix-icon-two:hover {
    background-image: url("./assets/version2/fix-icon2-hover.png");
  }
  .fix-icon-three {
    background-image: url("./assets/version2/fix-icon3.png");
  }
  .fix-icon-three:hover {
    background-image: url("./assets/version2/fix-icon3-hover.png");
  }
}
</style>