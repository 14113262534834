<template>
  <div class="card-cell fCenter">
    <div class="image_show" :class="[url]"></div>
    <div class="t_box fCenter">
      <div class="tb_titl1">{{title1}}</div>
      <div class="tb_titl2" v-if="title2">{{title2}}</div>
    </div>
    <div class="content">{{content}}</div>
  </div>
</template>

<script>
export default {
  props: {
    url: String,
    title1: String,
    title2: String,
    content: String,
    content2: String,
    backContent: String,
    backTitle: String,
  },
};
</script>

<style lang="scss" scoped>
.image_show {
  width: 120px;
  height: 120px;
}
.is_logo1 {
  background: url('../../assets/plan/ye_logo1.png') no-repeat center/contain;
}
.is_logo2 {
  background: url('../../assets/plan/ye_logo2.png') no-repeat center/contain;
}
.is_logo3 {
  background: url('../../assets/plan/ye_logo3.png') no-repeat center/contain;
}
.is_logo4 {
  background: url('../../assets/plan/ye_logo4.png') no-repeat center/contain;
}
.is_logo5 {
  background: url('../../assets/plan/ye_logo5.png') no-repeat center/contain;
}
.is_logo6 {
  background: url('../../assets/plan/ye_logo6.png') no-repeat center/contain;
}
.card-cell{
	width: 360px;
	height: 480px;
	background: #fff;
	border-radius: 16px;
  box-shadow: 0px 4px 20px 4px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
	img {
    width: 120px;
    height: 120px;
  }
  .t_box {
    width: 195px;
    height: 75px;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 24px;
    div {
      font-size: 24px;
      color: #333;
      font-weight: bold;
      line-height: 1.5;
    }
  }
	.titles {
    font-size: 24px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: bold;
    color: #353535;
  }
  .content {
    width: 310px;
    height: 145px;
    font-size: 20px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: center;
  }
}
</style>
