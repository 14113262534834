<template>
  <div>
    <div class="pages-help">
      <div class="top box">
        <div class="div-content content-center">
          <div class="title">请问有什么可以帮您？</div>
          <div class="search content-center">
            <el-input placeholder="请简单描述您遇到的问题" prefix-icon="el-icon-search" class="div-input" size="large" @keyup.enter.native="clickSearch" v-model="searchData">
            </el-input>
            <div class="div-search content-center" @click="clickSearch">搜索</div>
          </div>
          <div class="desc">
            <span>搜索热词：</span>
            <span class="text" @click="toSearch('浏览器')">浏览器</span>
            <span class="text" @click="toSearch('网页')">网页</span>
            <span class="text" @click="toSearch('键盘')">键盘</span>
          </div>
        </div>
      </div>
      <!-- 文档帮助 -->
      <div class="div-tab" v-show="searchType">
        <el-tabs class="box" v-model="activeName" @tab-click="tabClick">
          <!-- <div class="box"> -->
          <el-tab-pane label="文档帮助" name="1">
            <div class="video-box">
              <div style="width: 270px;border-right: 1px solid #e6e6e6;overflow: hidden;height: 100vh;position: relative;">
                <div class="inner">
                  <el-menu :collapse-transition="false" mode="vertical" :default-active="activeMenu">
                    <SidebarItem @findContent="findContent" v-for="(route, index) in menuData" :key="index" :item="route" />
                  </el-menu>
                </div>
              </div>
              <div class="html-div" style="width: 80%;">
                <div class="ql-container ql-snow">
                  <div class="ql-editor">
                    <div style="margin-left: 24px;" v-html="richtext"></div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="视频帮助" name="2">
            <div class="video-box">
              <div style="width: 20%;border-right: 1px solid #e6e6e6;overflow: hidden;height: 100vh;position: relative;">
                <div class="inner">
                  <el-menu :collapse-transition="false" mode="vertical" :default-active="activeMenu">
                    <SidebarItem @findContent="findVideo" v-for="(route, index) in menuData" :key="index" :item="route" />
                  </el-menu>
                </div>
              </div>
              <div class="image-box" style="width: 80%;">
                <div class="image-div" @click="lookVideo">
                  <img class="video-div" :src="videoData.thumb" />
                  <div class="image-text">{{videoData.title}}</div>
                </div>
              </div>
            </div>

          </el-tab-pane>

        </el-tabs>
      </div>
      <!-- 搜索结果 -->
      <div class="result-div" v-show="!searchType">
        <div class="top-text">{{ searchTitle }}</div>
        <el-tabs v-model="tabName" @tab-click="handleClick">
          <el-tab-pane label="全部" name="first"></el-tab-pane>
          <el-tab-pane label="文档" name="second"></el-tab-pane>
          <el-tab-pane label="视频" name="third" disabled></el-tab-pane>
          <div v-for="item in searchList" :key="item.id" @click="toDoc(item.id)">
            <HelpCard :list="item" />
          </div>
          <div style="text-align: right;margin-top: 48px;">
            <el-pagination layout="prev, pager, next" @current-change="handleCurrentChange" :page-size="20" :total="total * 1">
            </el-pagination>
          </div>
        </el-tabs>
        <div v-show="!total.length" class="search_nodata">
          <div class="sn_no"></div>
        </div>
      </div>

      <el-dialog :visible.sync="dialogVisible" :before-close="handleClose">
        <div class="content-center">
          <iframe v-if="dialogVisible" style="width: 100%;height: 600px;" :src="videoData.url" border="0" frameborder="no" framespacing="0" allowfullscreen></iframe>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import HelpCard from './components/HelpCard.vue';
import { help } from '../../api/index'
import { Base64 } from 'js-base64';
import SidebarItem from './components/SidebarItem.vue';

export default {
  name: 'profile',
  bodyClass: 'profile-page',
  components: {
		HelpCard,
		SidebarItem
  },
	data() {
    return {
      searchData: '',
			dialogVisible: false,
			searchType: true,

			activeName: '1',
			tabName: 'first',
			menuData: [], // 树菜单
			activeMenu: "", // 激活菜单
			searchList: [], // 搜索内容
			total: 0, // 条数
			richtext: '', // 文档内容
			videoData: {}, // 视频信息
      searchTitle: '请搜索',

    };
  },
	created(){
		// this.getCategorg()
		this.getTreeData()
	},
	methods: {
		handleCurrentChange(val){
			this.currentPage = val
			this.search()
		},
		// 跳转对应的文档
		toDoc(id){
			this.searchType = true
			this.activeMenu = id
			this.findContent(id)
		},
		// 搜索
		toSearch(text){
			this.searchData = text
			this.clickSearch()
		},
		// 获取文档内容
		findContent(id){
			help.getDoc(id).then(res => {
				if(res.data.data){
					this.richtext = Base64.decode(res.data.data.richtext)
				} else {
					this.richtext = '<p>暂无数据</p>'
				}
			})
		},
		// 获取视频内容
		findVideo(id){
			help.getVideo(id).then(res => {
				this.videoData = res.data.data || {}
			})
		},
		// 获取树
		getTreeData (){
			help.getTree(this.activeName).then(res =>{
				this.menuData = res.data.data.childNodes
				if(res.data.data.childNodes && res.data.data.childNodes.length > 0){
					if(res.data.data.childNodes[0].childNode && res.data.data.childNodes[0].childNode.length > 0){
						if(res.data.data.childNodes[0].childNode[0].childNode && res.data.data.childNodes[0].childNode[0].childNode.length > 0){
							this.activeMenu = res.data.data.childNodes[0].childNode[0].childNode[0].id
							
						}	else {
							this.activeMenu = res.data.data.childNodes[0].childNode[0].id
						}
					} else {
						this.activeMenu = res.data.data.childNodes[0].id
					}
				}
				if(this.activeName == 1){
					this.findContent(this.activeMenu)
				} else {
					this.findVideo(this.activeMenu)
				}
			})
		},
		// 切换tab
		tabClick(tab, event) {
			console.log(tab, event);
			this.activeMenu = ''
			this.activeName = tab.name
			console.log(this.activeName)
			this.getTreeData()
		},
		// 点击搜索
		clickSearch(){
			if(this.searchData === ''){
				this.searchType = true
			} else {
				this.search()
			}
		},
		// 搜索内容
		search(){
      this.searchList = []
			help.searchFunc({
				pageSize: 20,
				currentPage: this.currentPage,
				type: 1, // type 1是文档 2是视频	
				keyword: this.searchData
			}).then(res => {
				if (res.data.data.length > 0){
					this.searchList = res.data.data
          this.total = res.data.pager.total
				} else {
          this.total = 0
        }
        
        this.searchType = false
        this.searchTitle = `搜索到${this.total}条“${this.searchData}”的结果`
			})
		},
		// 查看视频
		lookVideo(){
			this.dialogVisible = true
		},
		// 帮助详情
		goToDetail(){
			this.$router.push({
        name: 'helpDetail',
      })
		},

		// tab 切换
		handleClick(tab, event) {
			console.log(tab, event);
			this.search()
		},
		// 关闭弹窗
		handleClose(){
			this.dialogVisible = false
		},

	}
	
};
</script>

<style lang="scss" scoped>
.search_nodata {
  width: 100%;
  height: 300px;
  .sn_no {
    width: 295px;
    height: 285px;
    background: url("../../assets/about/no_data.png") no-repeat center/cover;
    margin: 30px auto 0;
  }
}
.pages-help {
  min-width: 1100px;
  min-height: 100vh;
  background: #fff;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;

  /deep/ .el-submenu__icon-arrow {
    right: 30px;
  }

  /deep/ .el-tabs__nav-scroll {
    height: 60px;
    display: flex;
    align-items: center;
    line-height: 60px;
  }

  /deep/ .el-tabs__item.is-top {
    font-size: 18px;
    font-weight: 500;
    color: #222;
  }

  /deep/ .el-menu-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /deep/ .el-menu-item.is-active {
    background: none;
  }

  /deep/ .el-submenu__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      background: rgba(253, 103, 30, 0.1);
    }
  }

  .box {
    padding: 0 15%;
  }

  .content-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .top {
    height: 360px;
    width: 100%;
    background-image: url("../../assets/version2/help_bg.png");
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    // background-position: top center;
    // background: #000;
    .div-content {
      padding-top: 100px;
      flex-direction: column;

      .title {
        font-size: 36px;
        height: 50px;
        font-size: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 50px;
        margin-bottom: 24px;
      }

      .search {
        width: 100%;
        display: flex;
        margin: 26px 0 22px 0;
        .div-search {
          width: 125px;
          height: 40px;
          background: #fd671e;
          color: #fff;
          font-size: 16px;
          border-radius: 20px;
          margin-left: 18px;
          font-weight: 400;
          &:hover {
            background: rgba(253, 103, 30, 0.8);
            cursor: pointer;
          }
        }

        .div-input {
          width: 680px;
        }
      }

      .desc {
        font-size: 16px;
        color: #fff;

        .text {
          border-bottom: 1px solid #616161;
          margin-right: 10px;
          cursor: pointer;
          color: #fff;
          font-weight: 500;
          text-decoration: underline;
          &:hover {
            color: #fd671e;
          }
        }
      }
    }
  }

  .div-tab {
    width: 100%;
    margin-bottom: 50px;

    .html-div {
      margin: 0 10px;
    }

    .tab-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .tab-content {
      width: 330px;
      height: 257px;
      flex-direction: column;
      background: #ffffff;
      border: 1px solid #bfbfbf;
      border-radius: 24px;
      margin-bottom: 48px;
      font-size: 14px;

      .div-title {
        font-size: 18px;
        color: #616161;
        margin: 5px 0;
        width: 300px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .div-text {
        color: #b5b5b5;
        margin: 5px 0;
        width: 300px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .div-more {
        color: #fc6002;
        margin: 5px 0;
        width: 300px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  /deep/ .el-tabs--border-card {
    border: none;
    box-shadow: none;
  }

  /deep/ .el-tabs__item.is-active {
    color: #fc6002 !important;
  }

  /deep/ .el-tabs__active-bar {
    background-color: #fc6002;
  }
  /deep/
    .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item:not(.is-disabled):hover {
    color: #fc6002;
  }

  /deep/ .el-tabs__item:hover {
    color: #fc6002;
  }
  /deep/ .el-input__inner {
    border-radius: 20px;
    &:hover {
      border: 1px solid #fd671e;
    }
    &:focus {
      border: 1px solid #fd671e;
    }
  }

  /deep/ .el-menu-item.is-active {
    color: #fc6002;
  }

  /deep/ .el-menu-item {
    &:hover {
      background: rgba(253, 103, 30, 0.1);
    }
  }

  /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
    color: #fc6002;
    background: #fff;
    border: 1px solid #fc6002;
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #fc6002;
  }
  /deep/ .el-pagination.is-background .el-pager li {
    background-color: #fff;
    border: 1px solid #c2c2c2;
    color: #616161;
  }
  /deep/ .el-pagination.is-background {
    .btn-next {
      background-color: #fff;
      border: 1px solid #c2c2c2;
      color: #616161;
    }
    .btn-prev {
      background-color: #fff;
      border: 1px solid #c2c2c2;
      color: #616161;
    }
  }

  .video-box {
    display: flex;
    /deep/ .el-menu {
      border: 0;
    }
    .inner {
      position: absolute;
      left: 0;
      top: 0;
      right: -17px;
      bottom: 0;
      overflow-x: hidden;
      overflow-y: scroll;
    }

    .image-box {
      display: flex;
      flex-wrap: wrap;
    }

    .image-div {
      display: flex;
      flex-direction: column;
      width: 376px;
      margin: 10px 10px 10px 10px;
      align-items: center;

      .image-text {
        font-size: 14px;
        margin-top: 10px;
        color: #636363;
      }
    }
  }

  .video-div {
    width: 376px;
    height: 212px;
  }
  .result-div {
    padding: 0 15%;
    margin-bottom: 50px;
    /deep/ .el-tabs__nav-wrap::after {
      background: none;
    }

    /deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
      padding-left: 20px;
    }
    /deep/ .el-tabs__item.is-active {
      color: #fc6002 !important;
      width: 88px;
      background: #feefe8;
      text-align: center;
      border-radius: 4px;
    }
    /deep/ .el-tabs__active-bar {
      display: none;
    }
    .top-text {
      font-size: 14px;
      color: #000;
      margin-bottom: 36px;
      margin-top: 20px;
    }
  }
}
</style>
