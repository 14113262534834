<template>
  <div class="version-page">
    <div class="vp_con">
      <div class="banner-con">
        <h1 class="h1-seo">感谢下载UiFox</h1>
        <h1 class="dwn_tips">个别电脑在下载和安装过程中可能会出现阻止下载和安装的风险提示，请忽略，继续下载安装就好，不存在任何风险。</h1>
        <div class="dwn_wx fCenter">
          <div class="dw_left"></div>
          <div class="dw_right fCenter">
            <div class="dr_item fCenter">
              <div class="dri_logo"></div>
              <div class="dri_t">扫码添加UiFox用户顾问微信</div>
            </div>
            <div class="dr_item fCenter">
              <div class="dri_box fCenter">
                <div class="dri_icon"></div>
              </div>
              <div class="dri_t">一对一咨询，解决您的产品使用问题</div>
            </div>
            <div class="dr_item fCenter">
              <div class="dri_box fCenter">
                <div class="dri_icon"></div>
              </div>
              <div class="dri_t">拉您进入UiFox产品交流群共同学习</div>
            </div>
          </div>
        </div>
      </div>
      <div class="pr_contactus">
        <div class="prc_title">企业办公自动化咨询</div>
        <div class="contact-us-page fCenter">
          <div class="contact-left">
            <div class="contact-left-title">我们提供的服务</div>
            <div class="service-content">
              <div class="service-item">
                <img src="../assets/version2/service-icon.png">
                <div class="service-desc">提供企业咨询服务，快速获取行业自动化解决方案</div>
              </div>
              <div class="service-item">
                <img src="../assets/version2/service-icon.png">
                <div class="service-desc">企业级应用开发，强大技术支持，快速提升运营效率</div>
              </div>
              <div class="service-item">
                <img src="../assets/version2/service-icon.png">
                <div class="service-desc">企业个性化需求定制，专业实施团队，支持私有化部署</div>
              </div>
            </div>
            <img src="../assets/about/pr_22.png" class="contact-left-img">
          </div>
          <div class="contact-right">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
              <el-form-item label="姓名" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入姓名" style="width: 560px;" :maxlength="50" />
              </el-form-item>
              <el-form-item label="电子邮箱">
                <el-input v-model="ruleForm.email" placeholder="请输入电子邮箱" style="width: 560px;" :maxlength="50" />
              </el-form-item>
              <el-form-item label="手机" prop="phone">
                <el-input v-model="ruleForm.phone" placeholder="请输入手机号码" style="width: 560px;" :maxlength="11" />
              </el-form-item>
              <el-form-item label="公司名称" prop="companyName">
                <el-input v-model="ruleForm.companyName" placeholder="请输入公司名称" style="width: 560px;" :maxlength="50" />
              </el-form-item>
              <el-form-item label="留言" prop="note">
                <el-input type="textarea" v-model="ruleForm.note" placeholder="请输入留言" style="width: 560px;" :maxlength="500" show-word-limit />
              </el-form-item>
              <el-form-item style="width: 560px;margin-top:32px">
                <div class="btn content-center" @click="submitForm('ruleForm')">提交</div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from "@/components";
import { help } from '../api/index'

export default {
  name: "version",
  bodyClass: "index-page",
  components: {
    [Button.name]: Button,
  },
  computed: {},
  data() {
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|6|7|8|9][0-9]{9}$/
      if (!value) {
        return callback(new Error('电话号码不能为空'))
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('电话号码格式不正确'))
          }
        }
      }, 100)
    }
    return {
      ruleForm: {
        name: '',
        email: '',
        phone: '',
        companyName: '',
        profession: '',
        position: '',
        department: '',
        note: '',
      },
      rules: {
        name: [
          {required: true, message: '请输入姓名', trigger: 'blur'}
        ],
        email: [
          {required: true, message: '请输入正确的邮箱地址', trigger: 'blur'},
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
        phone: [
          {required: true, message: '请输入电话', trigger: 'blur'},
          { validator: checkPhone, trigger: 'blur' }
        ],
        companyName: [
          {required: true, message: '请输入公司名称', trigger: 'blur'}
        ],
      },
      
    };
  },
  methods: {
    submitForm(form){
      this.$refs[form].validate((valid) => {
        console.log(this.ruleForm);
        if(valid){
          help.getCrmAdd(this.ruleForm).then(res => {
            this.$confirm(res.data.data, '提示', {
              confirmButtonText: '确定',
              type: 'success'
            }).then(() => {
              //this.$router.push({path:'/'})
              this.ruleForm = {
                name: '',
                email: '',
                phone: '',
                companyName: '',
                profession: '',
                position: '',
                department: '',
                note: '',
              }
            }).catch(() => {
                    
            });
          })
        } else {
          return false
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-us-page {
  width: 1200px;
  box-shadow: 0px 4px 30px 4px rgba(0, 0, 0, 0.04);
  margin: 0 auto;
  margin-bottom: 32px;
  background: #fff9f4;
  .contact-left {
    width: 662px;
    background: #fff9f4;
    padding: 0 58px 58px 58px;
    .contact-left-title {
      font-size: 24px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
      line-height: 36px;
      letter-spacing: 1px;
      margin-bottom: 32px;
    }
    .service-content {
      .service-item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        img {
          width: 16px;
          height: 16px;
        }
        .service-desc {
          margin-left: 8px;
          font-size: 18px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #333333;
          letter-spacing: 1px;
        }
      }
    }
    .contact-left-img {
      margin-top: 80px;
    }
  }
  .contact-right {
    flex: auto;
    padding: 32px;
    background: #ffffff;
    /deep/label {
      margin-bottom: 0;
    }
    /deep/.el-input--mini .el-input__inner {
      height: 48px;
      line-height: 48px;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
    }
    /deep/.el-textarea__inner {
      height: 96px;
    }
    .content-center {
      width: 560px;
      height: 56px;
      background: linear-gradient(90deg, #fd621f 0%, #fb8a10 100%);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
      border-radius: 28px;
      font-size: 16px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      line-height: 56px;
    }
    /deep/.el-form-item--mini.el-form-item {
      margin-bottom: 38px;
    }
    /deep/.el-form-item--mini .el-form-item__error {
      padding-top: 8px;
    }
  }
}
.version-page {
  .pr_contactus {
    background: #fff;
    padding-top: 100px;
    .prc_title {
      width: 324px;
      height: 36px;
      font-size: 36px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
      line-height: 54px;
      margin: 0 auto;
      margin-bottom: 60px;
    }
  }
  .vp_con {
    width: 100vw;
    height: auto;
    background: #000;
    margin-top: 60px;
  }
  .banner-con {
    width: 100vw;
    height: 570px;
    background: url("../assets/about/pr_bg.png") no-repeat center/cover;
    padding-top: 140px;
    .h1-seo {
      width: 100%;
      font-weight: bold !important;
      font-size: 56px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: normal;
      color: #fff;
      text-align: center;
    }
    .dwn_tips {
      width: 832px;
      height: 16px;
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      line-height: 24px;
      margin: 0 auto;
    }
    .h3-seo {
      margin: 20px 0 30px 0;
      width: 465px;
      height: 36px;
      background: #fff;
      border-radius: 18px;
      font-size: 20px;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: #ff7f00;
      margin: 0 auto;
      margin-top: 10px;
    }
    .dwn_wx {
      width: 580px;
      height: 186px;
      border-radius: 8px;
      background: #fff;
      margin: 60px auto 0;
      .dw_left {
        width: 158px;
        height: 158px;
        background: url("../assets/about/code.png") no-repeat center/contain;
        margin-left: 24px;
      }
      .dw_right {
        flex: auto;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 24px;
        .dri_t {
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #333333;
          line-height: 24px;
        }
        .dri_logo {
          width: 28px;
          height: 28px;
          background: url("../assets/about/wx.png") no-repeat center/contain;
          margin-right: 8px;
        }
        .dri_box {
          width: 28px;
          height: 28px;
          margin-right: 8px;
          .dri_icon {
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background: #000;
          }
        }
      }
    }
  }
  .dowl_btn {
    color: #fff;
    font-size: 18px;
    border: 1px solid #fff;
    border-radius: 4px;
    width: 158px;
    height: 46px;
    cursor: pointer;
  }
}
</style>
