<template>
  <div class="card-cell fCenter" :class="[bg]">
    <div class="titles">{{title}}</div>
    <div class="right">
      <div class="content">{{content}}</div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    url: String,
    title: String,
    content: String,
    backContent: String,
    backTitle: String,
    bg: String,
  },
  components: {
    // [Carousel.name]: Carousel,
    // [CarouselItem.name]: CarouselItem
  }
};
</script>

<style lang="scss" scoped>
.bg1 {
  background: url('../../assets/plan/ye1.png') no-repeat center/cover;
}
.bg2 {
  background: url('../../assets/plan/ye2.png') no-repeat center/cover;
}
.bg3 {
  background: url('../../assets/plan/ye3.png') no-repeat center/cover;
}
.bg4 {
  background: url('../../assets/plan/ye4.png') no-repeat center/cover;
}
.titles {
  font-size: 22px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 33px;
  margin: 24px 0;
}
.card-cell{
	width: 290px;
	height: 250px;
	display: flex;
	position: relative;
	border-radius: 20px;
	overflow: hidden;
	img {
		width: 290px;
		height: 250px;
	}
	.right {
		display: flex;
		flex-direction: column;
		align-items: center;
    justify-content: center;
		position: absolute;
		top: 270px;
		width: 290px;
		height: 250px;
		background: #FC6000;
		opacity: 0.9;

		transition: All 0.4s ease-in-out;
		-webkit-transition: All 0.4s ease-in-out;
		-moz-transition: All 0.4s ease-in-out;
		-o-ttransition: All 0.4s ease-in-out;
		-ms-transition: All 0.4s ease-in-out;

		.content {
			width: 270px;
		  height: auto;
			font-size: 16px;
			font-family: SourceHanSansCN-Regular, SourceHanSansCN;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 1.8;
      padding: 10px;
      text-align: justify;
		}
	}
}

 .card-cell:hover {
	.right{
		transform:translate(0,-270px);  
		-webkit-transform:translate(0,-270px);
		-moz-transform:translate(0,-270px);    
		-o-transform:translate(0,-270px);    
		-ms-transform:translate(0,-270px);
	}
	
}
</style>
