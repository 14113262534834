<template>
  <div class="contact">
    <!-- <div class="page-header" filter-color="orange">
      <parallax class="page-header-image">
      </parallax>
      <div class="container">
        <div class="content-left brand">
          <div class="titles" style="left: 80px;">关于我们</div>
        </div>
      </div>
    </div> -->

    <div class="banner-back">
      <div style="height: 180px;"></div>
      <div class="titles content-center">联系我们</div>
      <div class="title-desc content-center">请填写表单信息，UiFox团队将尽快与您联系！</div>
      <div class="content-center">
        <div class="content-box">
          <div style="margin: 0 81px;">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
              <el-form-item label="姓名" prop="name" style="margin-top: 10px;">
                <el-input v-model="ruleForm.name" placeholder="请输入姓名" style="width: 450px;" />
              </el-form-item>
              <el-form-item label="电子邮箱">
                <el-input v-model="ruleForm.email" placeholder="请输入电子邮箱" style="width: 450px;" />
              </el-form-item>
              <el-form-item label="手机" prop="phone">
                <el-input v-model="ruleForm.phone" maxlength="11" placeholder="请输入手机号码" style="width: 450px;" />
              </el-form-item>
              <el-form-item label="公司名称" prop="companyName">
                <el-input v-model="ruleForm.companyName" placeholder="请输入公司名称" style="width: 450px;" />
              </el-form-item>
              <el-form-item label="公司所在行业">
                <el-select v-model="ruleForm.profession" placeholder="请选择公司所在行业" style="width: 450px;">
                  <el-option v-for="item in professionList" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="职位">
                <el-select v-model="ruleForm.position" placeholder="请选择职位" style="width: 450px;">
                  <el-option v-for="item in positionList" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="部门">
                <el-select v-model="ruleForm.department" placeholder="请选择部门" style="width: 450px;">
                  <el-option v-for="item in departmentList" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="留言" prop="note">
                <el-input type="textarea" v-model="ruleForm.note" placeholder="请输入留言" style="width: 450px;" />
              </el-form-item>
              <el-form-item style="width: 481px;">
                <!-- <el-checkbox v-model="checked">
                  <div class="checkbox-div">我已阅读并接受<span @click="openDialog">《产品隐私声明》</span>，并同意通过电话、短信、电子邮件或其他电子方式接收UiFox产品和服务信息。</div>
                </el-checkbox> -->
                <!-- <div>我同意通过电话、短信、电子邮件或其他电子方式接受UIFox产和服务信息</div> -->
                <div class="btn content-center" @click="submitForm('ruleForm')">提交</div>
              </el-form-item>
            </el-form>
          </div>

        </div>
      </div>
    </div>

    <el-dialog class="dialog" :visible.sync="privacyDialog" width="40%" :before-close="handleClose" append-to-body>
      <img src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/contact/ysxy.png" />
      <!-- <div class="content-center" style="flex-direction: column;align-items:normal;">
        <div class="content-center dialog-title">隐私声明政策</div>
        <div class="dialog-content">杭州未斯科技有限公司及其子公司（简称“未斯科技”或“我们”）深知隐私对您的重要性，并会严格遵守国家相关法律法规，尊重您的隐私保护。请在向未斯科技提交个人数据之前，阅读并了解本《隐私声明政策》（下文简称“本声明政策”）。</div>
        <div class="dialog-title1">一、适用条件</div>
        <div>如果您对我们使用用户的个人信息有任何疑问或顾虑，请通过本隐私通知政策底部所示联系方式与我们联系。</div>
        <div>UiFox是未斯科技名下基于互联网和微服务架构的一体化在线研发和运营平台，。本声明政策阐述了未斯科技如何处理您的个人数据，但本声明政策可能并不涉及所有可能的数据处理情境。有关收集产品或服务特定数据的信息可能由未斯科技在补充政策中，或者在收集数据时提供的通知中发布。</div>
        <div>我们制定本声明政策的目的在于帮助您了解以下内容：</div>
        <div>1. 我们如何收集和使用您的个人数据</div>
        <div>2. 我们如何使用 Cookie 和同类技术</div>
        <div>3. 我们如何共享、转让、披露您的个人数据</div>
        <div>4. 未斯科技如何访问或修改您的个人数据</div>
        <div>5. 我们如何保护您的个人数据</div>
        <div>6. 本声明政策如何更新</div>
        <div>7. 如何联系我们</div>
        <div class="dialog-title1">二、声明政策内容</div>
        <div>1. 我们如何收集和使用您的个人数据</div>
        <div>（1）我们收集哪些您的个人信息</div>
        <div>个人数据是指单独使用或结合其他信息使用时能够确定个人身份的信息。此类数据会在您登录UiFox以及使用在线开发平台功能时由您直接提交给我们或者为了履行合同而统一录入，包括姓名、身份证件号码、账号、邮箱账号、电话号码、计算机名称、Mac地址、计算机IP等。前述个人信息为您使用我们提供的【中外运平台订舱、VGM/仓单/AMS发送、海运跟踪、开票】等业务功能及其研发改进所必须。</div>
        <div>此外，在您使用下列业务功能或服务时，我们的网站/平台/客户端会向您单独申请下列与个人信息相关的系统权限：</div>
        <div>（a）为保证您正常使用的【】功能/服务，我们需要收集您的【】个人信息。如不提供则无法使用该功能。</div>
        <div>（b）为保证您正常使用【】功能/服务，我们需要收集您的【】个人信息。如不提供则无法使用该功能。</div>
        <div>（c）为保证您正常使用【】功能/服务，我们需要收集您的【】个人信息。如不提供则无法使用该功能。</div>
        <div>在某些情况下，您可以选择不向未斯科技提供个人数据，但如果您选择不提供，未斯科技可能无法为您提供相关产品或服务，也无法回应或解决您所遇到的问题。</div>
        <div>（2）我们如何使用您的个人信息</div>
        <div>我们可能将您的个人数据用于以下目的：</div>
        <div>(a) 创建账户和登录。</div>
        <div>(b) 开展内部审计、数据分析和研究，改善我们的产品和服务。</div>
        <div>(c) 在您选择向我们发送错误详情的情况下排查错误。</div>
        <div>(d) 同步、共享和存储您上传或下载的数据以及执行上传和下载所需的数据。</div>
        <div>(e) 保护我们产品、服务和客户或用户的安全，执行与改善我们的防损和反欺诈计划。</div>
        <div>(f) 遵从和执行适用的法律要求，相关的行业标准或我们的政策。</div>
        <div>我们还可能收集和使用非识别性数据。非识别性数据是指无法用于确定个人身份的数据。例如，我们会收集汇总的统计数据，例如网站访问量。我们收集此数据的目的在于了解用户如何使用自己的网站、产品和服务。借此，我们可以改善自己的服务，更好地满足客户需求。我们可能会自行决定出于其他目的收集、使用、处理、转移或披露非识别性数据。 我们会尽力隔离您的个人数据和非识别性数据，并单独使用这两种数据。如果个人数据掺杂了非识别性数据，依旧会被视作个人数据处理。</div>
        <div>当我们处理您的个人数据时我们会遵从适用的法律的要求基于适当的合法性基础予以处理，包括：</div>
        <div>• 为履行合同处理您的个人数据；</div>
        <div>• 基于您的同意处理您的个人数据；</div>
        <div>• 为改善我们的产品和服务，执行与改善我们的防损和反欺诈计划等目的处理您的个人数据时我们将基于我们或第三方的合法利益。这些合法利益包括，使我们能够更有效的管理和运营我们的业务并提供我们的产品和服务；保护我们的业务、系统、产品、服务和客户的安全；内部管理，遵从内部的政策和流程；我们在本声明政策中描述的其他合法利益等；</div>
        <div>• 我们还可能基于遵从和执行法律义务处理您的个人数据。</div>
        <div>（3）从第三方获得您的个人信息</div>
        <div>为更好地为您提供服务，我们可能会接收关联公司、业务合作伙伴等第三方提供的您的相关个人信息。例如当您通过我们的关联公司、业务合作伙伴的客户端、网站订购我们的产品，您向其提供的预订信息可能会转交给我们，以便我们处理您的订单。我们会依据与第三方的约定、对接收的个人信息来源的合法性进行确认后，依相关法律、法规及第三方约定使用您的信息。</div>
        <div>（4）非识别性数据</div>
        <div>我们还可能收集和使用非识别性数据。非识别性数据是指无法用于确定个人身份的数据。例如，我们会收集汇总的统计数据，例如网站访问量。我们收集此数据的目的在于了解用户如何使用自己的网站、产品和服务。借此，我们可以改善自己的服务，更好地满足客户需求。我们可能会自行决定出于其他目的收集、使用、处理、转移或披露非识别性数据。我们会尽力隔离您的个人数据和非识别性数据，并单独使用这两种数据。如果个人数据掺杂了非识别性数据，依旧会被视作个人数据处理。</div>
        <div>2. 我们如何使用 Cookie 和同类技术</div>
        <div>为确保网站正常运转，我们有时会在计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 是一种网络服务器存储在计算机或移动设备上的纯文本文件。Cookie 的内容只能由创建它的服务器检索或读取。每个 Cookie 对您的网络浏览器或移动应用程序都是唯一的。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie，网站能够存储用户偏好或购物篮内的商品等数据。</div>
        <div>我们启用Cookie的目的与大多数网站或互联网服务提供商启用 Cookie 的目的一样，即改善用户体验。借助于 Cookie，网站能够记住用户的单次访问（使用会话 Cookie）或多次访问（使用永久 Cookie）。借助于 Cookie，网站能够保存设置，例如计算机或移动设备的语言、字体大小和其他浏览偏好。这意味着，用户无需在每次访问时重新配置用户偏好设置。如果某个网站不使用 Cookie，那么在用户每一次打开网页时，该网站都会将其视为新访客。例如，如果您登录某个网站后转到另一个网页，该网站就不会识别出您，而您会被再次注销。 我们不会将 Cookie 用于本声明政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见  https://www.aboutcookies.org/。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问以下链接：</div>
        <div>Internet Explorer：http://support.microsoft.com/kb/260971</div>
    　　 <div>{{'Google Chrome：https://support.google.com/chrome/bin/answer.py?hl=en-GB&answer=95647&p=cpn_cookies'}}</div>

        <div>Mozilla Firefox：http://support.mozilla.org/en-US/kb/Cookies</div>
        <div>Safari：http://support.apple.com/kb/PH5042</div>
        <div>Opera：http://www.opera.com/browser/tutorials/security/privacy/</div>
        <div>您使用我们的网站意味着您同意按照如上所述使用Cookie</div>
        <div>3. 我们如何共享、转让、披露您的个人数据</div>
        <div>（1）共享</div>
        <div>在某些服务由我们的授权合作伙伴提供的情况下，我们会如本声明政策描述与该合作伙伴共享您的个人数据。此外，我们可能在我们的关联公司间共享个人数据。</div>
        <div>（2）转让</div>
        <div>除以下情况外，我们不会将您的个人信息转让给任何公司、组织和个人。</div>
        <div>A.获得您的明确授权后，我们会向您授权的第三方转让您的个人信息。</div>
        <div>B.在涉及合并、收购或破产清算时，如涉及到个人信息转让时，我们会要求新的公司继续按本政策接收、管理、使用和转让您的信息。若无法达成共识，我们会要求新的运营主体重新向您征求授权。</div>
        <div>在适用的法律要求或响应法律程序的情况下，我们也可能会向相关的执法机关或者其他政府机关披露您的个人数据。在某些管辖区，如果我们牵涉到重组、合并或破产和清理诉讼，那么您的个人数据还可能会被披露给交易方。</div>
        <div>（3）披露</div>
        <div>我们还仅会在存在如下合理需求的情况下披露您的数据，且无须征得您的进一步同意，例如。</div>
        <div>A.关系国家安全、国防安全的情况时；</div>
        <div>B.关系公共安全、公共卫生、重大公共利益的情况时；</div>
        <div>C.关系犯罪侦查、起诉、审判和判决执行的情况时；</div>
        <div>D.出于维护个人信息主体或其他个人的生命、财产安全，但又很难得到本人同意的情况下；</div>
        <div>E.所收集的个人信息是个人信息主体自行向社会公众公开的信息；</div>
        <div>F.从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</div>
        <div>G.根据您的要求签订合同所必需的；</div>
        <div>H.用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；</div>
        <div>I.为合法的新闻报道所必需的；</div>
        <div>J.学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。</div>
        <div>K.法律法规规定的其他情形出于执行合同以及我们认为为阻止身体损害或财产损失或调查可能的或实际的非法行为有必要披露且披露是适当的。</div>
        <div>4. 如何访问或修改您的个人数据</div>
        <div>您应确保提交的所有个人数据都准确无误。我们会尽力维护个人数据的准确和完整，并及时更新这些数据。</div>
        <div>当适用的法律要求的情况下，您可能(1)有权访问我们持有的关于您的特定的个人数据；(2)要求我们更新或更正您的不准确的个人数据；(3)拒绝或限制我们使用您的个人数据；以及(4)要求我们删除您的个人数据。如果您想行使相关的权利，请访问http://www.uifox.com.cn/personal-data-request在线反馈给我们。为保障安全，您可能需要提供书面请求。如果我们有合理依据认为这些请求存在欺骗性、无法实行或损害他人隐私权，我们则会拒绝处理请求。</div>
        <div>当适用的法律要求的情况下，当我们基于您的同意处理您的个人数据时，您还有权随时撤销您的同意。但撤销同意不会影响撤销前我们基于您的同意处理您个人数据的合法性及效力，也不影响我们基于其他适当的正当性基础处理您的个人数据。</div>
        <div>5. 我们如何保护和留存您的个人数据</div>
        <div>我们重视个人数据的安全。我们采用适当的物理、管理和技术保障措施来保护您的个人数据不被未经授权访问、披露、使用、修改、损坏或丢失。例如，我们会使用加密技术确保数据的机密性；我们会使用保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人数据；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人数据重要性的认识。我们会尽力保护您的个人数据，但是请注意任何安全措施都无法做到无懈可击。</div>
        <div>我们将会在达成本声明政策所述目的所需的期限内保留您的个人数据，除非按照法律要求或许可需要延长保留期或受到法律的允许。因为基于不同的场景和产品及服务的不同，数据的存储期可能会有所不同，我们用于确定存留期的标准包括：完成该业务目的需要留存个人数据的时间，包括提供产品和服务，维护相应的交易及业务记录，管控并提升产品与服务性能与质量，保证系统、产品和服务的安全，应对可能的用户查询或投诉，问题定位等；用户是否同意更长的留存期间；法律、合同等是否有保留数据的特殊要求等。只要您的账户是为您提供服务必须，我们都将保留您的注册信息。您也可以选择注销您的账号，在您注销账号后，我们会停止基于该账号提供产品和服务，并在无特殊法律要求的情况下，删除您相应的个人数据。</div>
        <div>我们会按照法律法规规定，将境内收集的用户个人信息存储于中国境内，不存在境外收集用户信息情况。</div>
        <div>6. 本声明政策如何更新</div>
        <div>我们保留不时更新或修改本声明政策的权利。如果我们的隐私政策变更，我们会将最新版隐私政策发布在这里。如果我们对隐私政策做出了重大变更，我们还可能会通过不同渠道向您发送变更通知，例如，在我们的网站上发布通知。</div>
        <div>本条所指的更新包括但不限于：</div>
        <div>• 外部合法合规环境发生变化时；</div>
        <div>• 服务模式发生重大变化，如：个人数据的收集范围、使用目的等；</div>
        <div>• 个人数据处理方面的权利及其行使方式发生重大变化；</div>
        <div>• 联络方式及投诉渠道发生变化时。</div>
        <div>7. 如何联系我们</div>
        <div>如果您有任何隐私投诉或问题要联系未斯科技数据保护官，请访问http://www.uifox.com.cn/personal-data-request。</div>
        <div>当未斯科技按照本隐私政策处理您的个人数据时，响应您的请求、与您联系、向您提供产品或服务或与您达成或即将达成合同的未斯科技实体是相关个人数据的控制者。各未斯科技实体的联系信息可访问http://www.uifox.com.cn/contact-us?tab=Offices查看。</div>
        <div>最后更新时间: 2021年10月21日</div>
      </div> -->
    </el-dialog>

    <!-- <div style="height: 340px;">&nbsp;</div> -->

    <!-- <div class="div-content">
      <div class="content" style="margin-top: 100px;">杭州未斯科技有限公司成立于2019年12月，通过践行帮助企业建设数字化能力和推动企业数字化转型这一目标，综合运用RPA技术人技术、aPaaS低代码开发平台和AI技术为现代企业及生态开发者提供数智化产品及解决方案，并基于行业特性打造了UiFox机器人套装软件，帮助更多的企业全面提升内部工作效率，有效降低运营成本，在快速变化的市场环境下取得更好的发展和竞争优势。</div>
      <div class="content">公司核心团队大都来自阿里云、华为、IBM、德勤、埃森哲、字节跳动等国内互联网大厂和国际知名咨询公司，并拥有丰富的企业的数智化转型经验和落地实践。目前，公司获得国内顶级VC数千万元RMB天使轮融资。</div>
      <div class="content">公司总部位于杭州，并且在上海、福州、深圳等设有子公司或办事处。公司目前已服务超过100+企事业单位，行业覆盖电商（国内电商+跨境电商）、新零售、国际物流、教育、制造、地产、医疗、政务等重点领域。产品被广泛应用于数据采集、财务对账、税务申报、数据同步等业务场景中，在产品功能性、稳定性上均得到了客户的广泛认可。</div>
      <div class="content" style="margin-bottom: 150px;">作为一家以技术创新和服务至上的科技型企业，未斯科技将持续致力于云时代的企业数字化转型和实践，帮助公司实现资源整合，业务创新，从单一孤岛式的应用架构向统一的中台技术架构转变，在产业互联网的大背景下，通过数智化赋能企业全面转型升级，创造更大的商业价值。 </div>
    </div> -->
  </div>
</template>

<script>
import { help } from '../../api/index'

export default {
  name: "contact",
  data() {

    var checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (!value) {
        return callback(new Error('邮箱不能为空'))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的邮箱格式'))
        }
      }, 100)
    }

    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|6|7|8|9][0-9]{9}$/
      if (!value) {
        return callback(new Error('电话号码不能为空'))
      }
      setTimeout(() => {
        // Number.isInteger是es6验证数字是否为整数的方法,但是我实际用的时候输入的数字总是识别成字符串
        // 所以我就在前面加了一个+实现隐式转换

        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('电话号码格式不正确'))
          }
        }
      }, 100)
    }


    return {
      privacyDialog: false, // 隐私协议
      showBack: false,
      checked: false,
      options: [{
        value: '1',
        label: '黄金'
      }],

      ruleForm: {
        name: '',
        email: '',
        phone: '',
        companyName: '',
        profession: '',
        position: '',
        department: '',
        note: '',
      },
      rules: {
        name: [
          {required: true, message: '请输入姓名', trigger: 'blur'}
        ],
        email: [
          {required: true, message: '请输入正确的邮箱地址', trigger: 'blur'},
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
          // { validator: checkEmail, trigger: 'blur' }
        ],
        phone: [
          {required: true, message: '请输入电话', trigger: 'blur'},
          { validator: checkPhone, trigger: 'blur' }
        ],
        companyName: [
          {required: true, message: '请输入公司名称', trigger: 'blur'}
        ],
        profession: [
          {required: true, message: '请选择公司所在行业', trigger: 'change'}
        ],
        position: [
          {required: true, message: '请选择职位', trigger: 'change'}
        ],
        department: [
          {required: true, message: '请选择部门', trigger: 'change'}
        ],
      },
      departmentList: [],
      positionList: [],
      professionList: [],
    };
  },
  created() {
    this.getList()
  },
  methods: {
    // 打开 隐私协议 弹窗
    openDialog(){
      this.privacyDialog = true
    },
    handleClose(){
      this.privacyDialog = false
    },
    getList(){
      help.getCrm().then(res => {
        console.log(res)
        this.departmentList = res.data.data.department
        this.positionList = res.data.data.position
        this.professionList = res.data.data.profession
      })
    },
    submitForm(form){
      // if(!this.checked){
      //   this.$message({
      //     message: '请勾选服务信息',
      //     type: 'error'
      //   })
      //   return
      // }
      this.$refs[form].validate((valid) => {
        if(valid){
          console.log(111)
          help.getCrmAdd(this.ruleForm).then(res => {
            console.log(res)
            this.$confirm(res.data.data, '提示', {
              confirmButtonText: '确定',
              type: 'success'
            }).then(() => {
              this.$router.push({path:'/'})
            }).catch(() => {
                    
            });
            // this.$message({
            //   message: res.data.data,
            //   type: 'success'
            // })
          })
          // getCrmAdd
        } else {
          console.log(222)
          return false
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .dialog-title {
    font-size: 22px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #222222;
    line-height: 33px;
  }
  .dialog-title1 {
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #222222;
    line-height: 12px;
    margin: 20px 0;
  }
  .dialog-content {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #222222;
    line-height: 22px;
  }
}
/deep/ .el-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkbox-div {
  width: 460px;
  word-wrap: break-word;
  word-break: break-all;
  // display: inline-grid;
  white-space: pre-line;
  span {
    display: inline-block;
    color: #fc681c;
    cursor: pointer;
  }
}
.contact {
  height: 1211px;

  /deep/ .el-form-item__label {
    display: block !important;
  }
  .content-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .page-header {
    height: 1211px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  }
  .banner-back {
    height: 375px !important;
    // background-image: url("https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/contact/contact_bg.png");
    background-image: url("https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/guodu/banner6.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    box-shadow: 0px 20px 20px rgba(137, 137, 137, 0.13);

    .titles {
      font-size: 36px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #333;
      line-height: 63px;
      margin-bottom: 185px;
    }
    .title-desc {
      margin: 16px 0;
      font-size: 20px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #222222;
      line-height: 30px;
    }

    .content-box {
      display: flex;
      justify-content: center;
      // align-items: center;
      width: 582px;
      height: 900px;
      background: #fff;
      // box-shadow: 0 2px 18px #595b5e;
      border-radius: 8px;
    }

    .btn {
      width: 481px;
      height: 48px;
      background: linear-gradient(90deg, #fd621f 0%, #fb8a10 100%);
      border-radius: 4px;
      color: #fff;
      font-size: 18px;
    }
  }

  .div-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
  }

  .content {
    color: #707070;
    width: 1000px;
    line-height: 26px;
    font-size: 14px;
    margin-bottom: 30px;
  }
}
</style>
