<template>
  <div class="card-cell fCenter">
    <div class="left" :class="[url]"></div>
    <div class="right">
			<div class="titles">{{title}}</div>
			<div class="content">{{content}}</div>
		</div>
  </div>
</template>

<script>
// import { Carousel, CarouselItem } from 'element-ui';

export default {
  props: {
    url: String,
    title: String,
    content: String,
    content2: String,
    backContent: String,
    backTitle: String,
  },
  components: {
    // [Carousel.name]: Carousel,
    // [CarouselItem.name]: CarouselItem
  }
};
</script>

<style lang="scss" scoped>
.ab_bg1 {
  background: url('../../assets/plan/ab_logo1.png') no-repeat center/cover;
}
.ab_bg2 {
  background: url('../../assets/plan/ab_logo2.png') no-repeat center/cover;
}
.ab_bg3 {
  background: url('../../assets/plan/ab_logo3.png') no-repeat center/cover;
}
.ab_bg4 {
  background: url('../../assets/plan/ab_logo4.png') no-repeat center/cover;
}
.card-cell{
	width: 580px;
	height: 250px;
	background: #fff;
	border-radius: 20px;
  box-shadow: 0px 4px 20px 4px rgba(0, 0, 0, 0.04);
	.left {
		width: 68px;
    height: 68px;
	}
	.right {
		display: flex;
		flex-direction: column;
    justify-content: center;
    margin-left: 20px;
    
		.titles {
			font-size: 24px;
			font-family: SourceHanSansCN-Medium, SourceHanSansCN;
			font-weight: bold;
			color: #353535;
      margin-bottom: 16px;
		}
		.content {
			width: 450px;
			font-size: 20px;
			font-family: SourceHanSansCN-Regular, SourceHanSansCN;
			font-weight: 400;
			color: #353535;
			line-height: 1.8;
		}
	}
}
</style>
