<template>
	<div>
		<div class="pages-help">
			<div style="height: 80px;"></div>
			<!-- <div class="top box">
				<div class="div-content content-center">
					<div class="title">请问有什么可以帮您？</div>
					<div class="search content-center"> 
						<el-input
							placeholder="请简单描述您遇到的问题"
							prefix-icon="el-icon-search"
							class="div-input"
							size="medium"
							@keyup.enter.native="clickSearch"
							v-model="searchData">
						</el-input>
						<div class="div-search content-center" @click="clickSearch" >搜索</div>
					</div>
					<div class="desc">
						<span>搜索热词：</span>
						<span class="text" @click="toSearch('如何设置复选框')">如何设置复选框 </span>
						<span class="text" @click="toSearch('创建OCR引擎')">创建OCR引擎 </span>
						<span class="text" @click="toSearch('捕获元素常见问题')">捕获元素常见问题</span>
					</div>
				</div>
			</div> -->
			<!-- 文档帮助 -->
			<div class="div-tab" v-show="searchType">
				<el-tabs class="box" v-model="activeName" @tab-click="tabClick">
					<!-- <div class="box"> -->
						<el-tab-pane label="文档帮助" name="1">
							<div class="video-box">
								<div style="width: 20%;border-right: 1px solid #e6e6e6;">
									<el-menu
										:collapse-transition="false"
										mode="vertical"
										:default-active="aliasId"
									>
										<template v-for="menu in menuData" >
											<template v-if="menu.childNode.length > 0">
												<el-submenu 
													:key="menu.id"
													:index="menu.id" 
													:item="menu.id">
														<template slot="title" >
															<i class="el-icon-location"></i>
															<span>{{ menu.name }}</span>
														</template>
														<el-submenu v-for="item in menu.childNode" :key="item.id" :index="item.id">
															<template slot="title">{{item.name}}</template>
															<el-menu-item v-for="val in item.childNode" :key="val.id" :index="val.id"  @click="getDocDetail(val.id)">{{val.name}}</el-menu-item>
														</el-submenu>
												</el-submenu>
											</template>

											<template v-else>
												<el-menu-item :index="menu.id" :key="menu.id" @click="getDocDetail(menu.id)">
													<i class="el-icon-location"></i>
													<span slot="title">{{ menu.name }}</span>
												</el-menu-item>
											</template>
										</template>
										
									</el-menu>
								</div>
								<div class="html-div" style="width: 80%;">
									<!-- <div style="color: #000;">{{richtext}}123</div> -->
									<div style="margin-left: 24px;" v-html="richtext"></div>
								</div>
							</div>
							<!-- <div class="tab-box">
								<div @click="goToDetail" v-for="(item,idx) in 7" :key="idx" class="tab-content content-center" >
									<div>图片</div>
									<div class="div-title">产品介绍</div>
									<div class="div-text">RPA是什么</div>
									<div class="div-text">RPA能做什么</div>
									<div class="div-text">是如何做到的</div>
									<div class="div-more">了解更多 ></div>
								</div>
							</div> -->
						</el-tab-pane>
						<el-tab-pane label="视频帮助" name="2">
							<div class="video-box">
								<div style="width: 20%;border-right: 1px solid #e6e6e6;">
									<el-menu
										:collapse-transition="false"
										mode="vertical"
										:default-active="aliasId"
									>
										<template v-for="menu in menuData" >
											<template v-if="menu.childNode.length > 0">
												<el-submenu 
													:key="menu.id"
													:index="menu.id" 
													:item="menu.id">
														<template slot="title" >
															<i class="el-icon-location"></i>
															<span>{{ menu.name }}</span>
														</template>
														<el-submenu v-for="item in menu.childNode" :key="item.id" :index="item.id">
															<template slot="title">{{item.name}}</template>
															<el-menu-item v-for="val in item.childNode" :key="val.id" :index="val.id" @click="getVideoDetail(val.id)">{{val.name}}</el-menu-item>
														</el-submenu>
												</el-submenu>
											</template>

											<template v-else>
												<el-menu-item :index="menu.id" :key="menu.id" @click="getVideoDetail(menu.id)">
													<i class="el-icon-location"></i>
													<span slot="title">{{ menu.name }}</span>
												</el-menu-item>
											</template>
										</template>
										
									</el-menu>
								</div>
								<div class="image-box" style="width: 80%;">
									<div class="image-div content-center" @click="lookVideo">
										<!-- x5-video../../api/index='h5' x5-video-player-fullscreen='true' poster="imgSrc" -->
										<!-- <video style="object-fit:fill" class="video-div" src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/test.mp4" webkit-playsinline="true" playsinline="true" controls="controls"></video> -->
										<img class="video-div" :src="videoData.thumb" />
										<div class="image-text">{{videoData.title}}</div>
									</div>
								</div>
							</div>
							
						</el-tab-pane>
					<!-- </div> -->
					
				</el-tabs>
			</div>
			<!-- 搜索结果 -->
			<div class="result-div" v-show="!searchType">
				<div class="top-text">搜索到{{total}}条“如何搭建流程”的结果</div>
				<el-tabs v-model="tabName" @tab-click="handleClick">
					<el-tab-pane label="全部" name="first"></el-tab-pane>
					<el-tab-pane label="文档" name="second"></el-tab-pane>
					<el-tab-pane label="视频" name="third" disabled></el-tab-pane>
					<div v-for="item in searchList" :key="item" @click="toDoc(item.id)">
						<HelpCard :list="item"   />
					</div>
					<div style="text-align: right;margin-top: 48px;">
							<!-- background="true" -->
						<el-pagination
							layout="prev, pager, next"
							:total="total">
						</el-pagination>
					</div>
				</el-tabs>

			</div>


			<el-dialog
				:title="videoData.title"
				:visible.sync="dialogVisible"
				:before-close="handleClose">
				<div class="content-center">
					<video :src="videoData.url" webkit-playsinline="true" playsinline="true" controls="controls"></video>
				</div>
			</el-dialog>
			
			<!-- <el-tabs type="border-card">
				<el-tab-pane label="用户管理">用户管理
					<video src="movie.ogg" controls="controls"></video>
				</el-tab-pane>
				<el-tab-pane label="配置管理">配置管理</el-tab-pane>
				<el-tab-pane label="角色管理">角色管理</el-tab-pane>
				<el-tab-pane label="定时任务补偿">定时任务补偿</el-tab-pane>
			</el-tabs> -->
	  </div>
	</div>
</template>

<script>
// import { Tabs, TabPane } from '@/components';
import HelpCard from './components/HelpCard.vue';
import { help } from '../../api/index'
import { Base64 } from 'js-base64';


export default {
  name: 'profile',
  bodyClass: 'profile-page',
  components: {
		HelpCard
    // Tabs,
    // TabPane
  },
	data() {
    return {
      searchData: '',
			dialogVisible: false,
			aaa: '<div>123</div>',
			searchType: true,

			activeName: '1',
			tabName: 'second',
			menuData: [], // 树菜单
			activeMenu: '', // 激活菜单
			aliasId: '',
			searchList: [], // 搜索内容
			total: 0, // 条数
			richtext: '', // 文档内容
			videoData: {}, // 视频信息

    };
  },
	created(){
		// this.getCategorg()
		this.getTreeData()
	},
	methods: {
		// 跳转对应的文档
		toDoc(id){
			console.log(111)
			this.searchType = true
			this.activeMenu = id
			this.getDocDetail(id)
		},
		// 获取文档内容
		getDocDetail(id){
			help.getDoc(id).then(res => {
				console.log(res, '=====res=====')
				console.log(res.data.data)
				this.richtext = Base64.decode(res.data.data.richtext)
				console.log(this.richtext)
			})
		},
		// 获取视频内容
		getVideoDetail(id){
			help.getVideo(id).then(res => {
				console.log(res, '=====res=====')
				this.videoData = res.data.data
			})
		},
		// 通过aliasId获取Id
		getId(arr){
			const needArr = arr.filter(val => val.aliasId === this.aliasId)
			if(needArr.length > 0) return needArr[0]
			let needData = null
			for(let i = 0; i < arr.length; i++){
				if(arr[i].childNode && arr[i].childNode.length > 0){
					needData = this.getId(arr[i].childNode)
					if(needData) break
				}
				if(arr[i].childNodes && arr[i].childNodes.length > 0){
					needData = this.getId(arr[i].childNodes)
					if(needData) break
				}
			}
			return needData
		},
		// 获取树
		getTreeData (){
			help.getTree(this.activeName).then(res =>{
				console.log(res)
				this.menuData = res.data.data.childNodes
				this.aliasId = this.$route.query.aliasId
				this.activeMenu = this.getId(this.menuData).id
				console.log(this.activeMenu)

				// this.menuData.forEach(item1 => {
				// 	if(item1.aliasId === this.aliasId){
				// 		this.activeMenu = item1.id
				// 	}
				// 	item1.childNode.forEach(item2 => {
				// 		if(item2.aliasId === this.aliasId){
				// 			this.activeMenu = item2.id
				// 		}
				// 		item2.childNode.forEach(item3 => {
				// 			if(item3.aliasId === this.aliasId){
				// 				this.activeMenu = item3.id
				// 			}
				// 		})
				// 	})
				// })

				if(this.activeName == 1){
					this.getDocDetail(this.activeMenu)
				} else {
					this.getVideoDetail(this.activeMenu)
				}
			})
		},
		// 切换tab
		tabClick(tab, event) {
			console.log(tab, event);
			this.getTreeData()
		},
		// 点击搜索
		clickSearch(){
			if(this.searchData === ''){
				this.searchType = true
			} else {
				this.search()
			}
		},
		// 搜索内容
		search(){
			help.searchFunc({
				pageSize: 20,
				currentPage: 1,
				type: 1, // type 1是文档 2是视频	
				keyword: this.searchData
			}).then(res => {
				if (res.data.data.length > 0){
					this.searchType = false
					this.searchList = res.data.data
					this.total = res.data.pager.total
				} else {
					// this.searchType = true
					this.$message.error('暂无数据')
				}
			})
		},
		// 
		// getCategorg(){
		// 	console.log(1)
		// 	console.log(help)
		// 	help.getCategorgBot().then(res => {
		// 		console.log(res)
		// 	})
		// },
		// 查看视频
		lookVideo(){
			this.dialogVisible = true
		},
		// 帮助详情
		goToDetail(){
			this.$router.push({
        name: 'helpDetail',
      })
		},

		// tab 切换
		handleClick(tab, event) {
			console.log(tab, event);
			this.search()
		},
		// 关闭弹窗
		handleClose(){
			this.dialogVisible = false
		},

	}
	
};
</script>

<style lang="scss" scoped>
.pages-help{
	min-width: 1100px;
	min-height: 100vh;
	background: #fff;
	font-family: SourceHanSansCN-Bold,SourceHanSansCN;

	/deep/ .el-menu-item {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	/deep/ .el-submenu__title {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.box {
		padding: 0 15%;
	}

	.content-center {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.top {
		height: 360px;
		width: 100%;
		// background: hotpink;
		background: url('https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/help/help_bg.jpg') no-repeat center center;
		background-size: cover;
		// background-size: 100% 100%;
		// background-repeat: no-repeat;
		// position: relative;
		.div-content {
			// position: absolute;
			// top: 132px;
			padding-top: 100px;
			flex-direction: column;
			
			.title {
				font-size: 36px;
				color: #000;
			}

			.search {
				width: 100%;
				display: flex;
				margin: 26px 0 22px 0;
				.div-search {
					// width: 215px;
					width: 10%;
					height: 36px;
					background: #ff5100;
					color: #fff;
					font-size: 14px;
				}

				.div-input {
					// width: 863px;
					width: 50%;
				}
			}
			

			.desc {
				font-size: 14px;
				color: #616161;

				.text {
					// text-decoration: underline;
					border-bottom: 1px solid #616161;
					margin-right: 10px;
					cursor: pointer;
					// padding-bottom: 3px;
				}
			}
		}
	}

	// /deep/ .el-tabs__content {
	// 	display: flex;
	// 	width: 100%;
	// }

	.div-tab {
		width: 100%;
		margin-bottom: 50px;

		.html-div {
			margin: 0 10px;
		}

		.tab-box {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

		}

		.tab-content {
			width: 330px;
			height: 257px;
			flex-direction: column;
			background: #FFFFFF;
			border: 1px solid #BFBFBF;
			border-radius: 24px;
			margin-bottom: 48px;
			font-size: 14px;

			.div-title {
				font-size: 18px;
				color: #616161;
				margin: 5px 0;
				width: 300px;
				text-align: center;
				overflow: hidden;
				text-overflow:ellipsis;
				white-space: nowrap;
			}

			.div-text {
				color: #B5B5B5;
				margin: 5px 0;
				width: 300px;
				text-align: center;
				overflow: hidden;
				text-overflow:ellipsis;
				white-space: nowrap;
			}

			.div-more {
				color: #FC6002;
				margin: 5px 0;
				width: 300px;
				text-align: center;
				overflow: hidden;
				text-overflow:ellipsis;
				white-space: nowrap;
			}
		}
	}

	.result-div {
		padding: 0 15%;
		margin-bottom: 50px;
		/deep/ .el-tabs__nav-wrap::after {
			background: none;
		}
		/deep/ .el-tabs__header {
			background: #F9F4F0;
		}
		/deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2){
			padding-left: 20px;
		}
		.top-text {
			font-size: 14px;
			color: #000;
			margin-bottom: 36px;
			margin-top: 20px;
		}
	}

	/deep/ .el-tabs--border-card {
		border: none;
		box-shadow: none;
	}

	/deep/ .el-tabs__item.is-active {
		color: #FC6002 !important;
	}

	/deep/ .el-tabs__active-bar { 
		background-color: #FC6002;
	}
	/deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item:not(.is-disabled):hover {
		color: #FC6002;
	}

	/deep/ .el-tabs__item:hover {
		color: #FC6002;
	}
	/deep/ .el-input__inner {
		border-radius: 0;
	}

	/deep/ .el-menu-item.is-active {
		color: #FC6002;
	}

	/deep/ .el-menu-item:focus, .el-menu-item:hover {
		background-color: none;
	}

	/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
		color: #FC6002;
		background: #fff;
		border: 1px solid #FC6002;
	}
	/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
		color: #FC6002;
	}
	/deep/ .el-pagination.is-background .el-pager li{
		background-color: #fff;
		border: 1px solid #C2C2C2;
    color: #616161;
	}
	/deep/ .el-pagination.is-background {
		.btn-next {
			background-color: #fff;
			border: 1px solid #C2C2C2;
			color: #616161;
		}
		.btn-prev {
			background-color: #fff;
			border: 1px solid #C2C2C2;
			color: #616161;
		}
		
	}

	.video-box {
		display: flex;
		/deep/ .el-menu {
			border: 0;
		}

		.image-box {
			display: flex;
			flex-wrap: wrap;
			
		}

		.image-div {
			display: flex;
			flex-direction: column;
			width: 376px;
			margin: 10px 10px 10px 10px;

			.image-text {
				font-size: 14px;
				margin-top: 10px;
				color: #636363;
			}
		}
	}

	.video-div {
		width: 376px;
		height: 212px;
	}
}
</style>
