<template>
  <footer style="background: #fff" class="footer">
    <div class="footer_top flexCenter">
      <div class="ft_logo flexCenter">
        <div class="ftl_t"></div>
        <div class="ftl_b"></div>
      </div>
      <div class="ft_c_info flexCenter" style="width: 280px">
        <div class="ftci_t">杭州未斯科技有限公司</div>
        <div class="ftci_d">地址：杭州市余杭区菜鸟智谷产业园一期A2-1002室</div>
        <div class="ftci_d">联系电话：400-895-1888</div>
        <div class="ftci_d">邮箱：cd@uifox.com.cn</div>
      </div>
      <div class="ft_c_info flexCenter" style="width: 100px">
        <div class="ftci_t">友情链接</div>
        <div
          class="ftci_d cursor"
          @click="goHref('https://www.eline56.com/#/login?source=LSgmu8y')"
        >
          e航无忧
        </div>
      </div>
      <div class="ft_c_info flexCenter" style="width: 110px">
        <div class="ftci_t">关于我们</div>
        <div class="ftci_d cursor" @click="goAbout">公司简介</div>
        <div class="ftci_d cursor" @click="goAbout">发展历程</div>
        <div class="ftci_d cursor" @click="goAbout">我们的投资人</div>
        <div class="ftci_d cursor" @click="goContact">联系我们</div>
      </div>
      <div
        class="ft_c_info flexCenter"
        style="align-items: center; margin-left: 0px"
      >
        <div class="ftci_t" style="text-align: center">扫码关注公众号</div>
        <div class="ftci_pic ftci_pic_wechat" style="margin-top: 10px"></div>
      </div>
      <div
        class="ft_c_info flexCenter"
        style="align-items: center; margin-left: 0px; width: 170px"
      >
        <div class="ftci_t" style="text-align: center">扫码进群</div>
        <div class="ftci_pic" style="margin-top: 10px"></div>
      </div>
    </div>
    <div class="footer_bottom flexCenter">
      Copyright &copy; 2019-{{ year }} 杭州未斯科技有限公司<a
        href="https://beian.miit.gov.cn/"
        target="_blank"
        style="color: #666666; margin-left: 4px; text-decoration: none"
        >浙ICP备20009552号-1</a
      >
    </div>
  </footer>
</template>

<script>
import moment from "moment";
export default {
  props: {
    backgroundColor: String,
    type: String,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  methods: {
    // 跳转其他页面
    goHref(e) {
      window.open(e);
    },
    goProduct() {
      this.$router.push({ path: "/product/product" });
    },
    goAbout() {
      if (window.location.hash.includes("about")) return;
      this.$router.push({ path: "/about/about_us" });
    },
    goContact() {
      window.open("https://wpa1.qq.com/yGWcFLfo?_type=wpa&qidian=true");
    },
    goPlan(e) {
      if (e == 1) {
        this.$router.push({ path: "/solutions/international_logistics" });
      }
      if (e == 2) {
        this.$router.push({ path: "/solutions/local_e-commerce" });
      }
      if (e == 3) {
        this.$router.push({ path: "/solutions/cross-border_e-commerce" });
      }
    },
  },
};
</script>

<style lang="scss">
.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer {
  width: 100vw;
  height: 410px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .footer_top {
    width: 1200px;
    height: 350px;
  }
  .footer_bottom {
    width: 100%;
    height: 60px;
    border-top: 1px solid #dedede;
    color: #666;
    font-size: 12px;
    margin-top: 20px;
  }
  .ft_logo {
    height: 64px;
    width: 155px;
    flex-direction: column;
    justify-content: flex-start;
    background: url("https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/first/bottom_logo.png")
      no-repeat center/cover;
    background-size: 100% 100%;
    margin-right: 20px;
  }
  .ft_c_info {
    height: 200px;
    width: 250px;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 30px;
    align-items: flex-start;
    margin-left: 40px;
    .ftci_t {
      width: 100%;
      font-size: 16px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #353535;
      margin-bottom: 24px;
    }
    .ftci_d {
      margin-top: 10px;
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 500;
      color: #666666;
      line-height: 18px;
    }
    .ftci_pic {
      width: 130px;
      height: 130px;
      background: #eee;
      background: url("../assets/version2/qq.png") no-repeat center/cover;
      background-size: 100% 100%;
    }
    .ftci_pic_wechat {
      background: url("https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/first/wxgzh.png")
        no-repeat center/cover;
    }
  }
}
.cursor {
  cursor: pointer;
}
.cursor:hover {
  color: rgb(252, 96, 2) !important;
}
</style>
