import { render, staticRenderFns } from "./MainNavbar.vue?vue&type=template&id=6058cd9a&scoped=true"
import script from "./MainNavbar.vue?vue&type=script&lang=js"
export * from "./MainNavbar.vue?vue&type=script&lang=js"
import style0 from "./MainNavbar.vue?vue&type=style&index=0&id=6058cd9a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6058cd9a",
  null
  
)

export default component.exports