<template>
  <div class="electricity-page">
    <div class="page-header">
      <div class="ph_box fCenter">
        <div class="pc_left fCenter">
          <div class="pcl_title">国内电商解决方案</div>
          <div class="pcl_desc">RPA技术解决了电商行业ERP、CRM、OMS等软件无法解决的问题，满足了客户多样性和敏捷创新的诉求。</div>
          <DownloadBtn />
        </div>
        <div class="pc_right"></div>
      </div>
    </div>

    <div class="work-div" style="height: 831px;background: #fff;">
      <div class="text" style="margin: 0px 0 120px;">
        业务痛点分析
      </div>
      <div class="box">
        <div class="uifox-box">
          <div style="position: absolute;top: -50px;">
            <img src="../../assets/plan/in_logo1.png" width="100" height="100" />
          </div>
          <div class="box-title1">日常工作机械重复</div>
          <div class="box-content">海量SKU在多个平台快速增长，但是日常工作被频繁的ctl+c、ctl+v占据，本来具有创意的工作活生生变成了枯燥、机械的重复工作。</div>
        </div>
        <div class="uifox-box" style="margin: 0 20px 20px 20px;">
          <div style="position: absolute;top: -50px;">
            <img src="../../assets/plan/in_logo2.png" width="100" height="100" />
          </div>
          <div class="box-title1">业务协同效率低下</div>
          <div class="box-content">电商运营工作需要同时对接多个系统，系统之间无法全部通过API接口对接，因此系统间的数据同步工作避无可避，运营同学苦不堪言。</div>
        </div>
        <div class="uifox-box">
          <div style="position: absolute;top: -50px;">
            <img src="../../assets/plan/in_logo3.png" width="100" height="100" />
          </div>
          <div class="box-title1">敏捷创新速度太慢</div>
          <div class="box-content">电商业务的快速发展与系统建设滞后的矛盾一直没有彻底的解决方法，无法满足如今快速变化的市场和竞争环境。</div>
        </div>
      </div>
      <div class="uifox-box-last fCenter" style="width: 1200px;height:277px;">
        <img 
          class="ubl_image1"
          src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/ds/1.png" width="67" height="200" />
        <div style="margin-top: 46px;">
          <div class="box-content1">随着电商业务量的不断增长，电商日常运营要面对的各类信息化操作越来越多，而这些操作大多机械、单调且重复，比如维护好了一份SKU后，还需要在多个不同的平台上更新。又比如批量催付、促销信息批量发送等消息推送场景，由于平台的限制，只能逐一人工发送，涉及到大量重复的复制粘贴工作。诸如此类的流程明确、操作重复的工作占据了日常运营大量的时间，很难专注于更重要的运营分析、业务创新工作上。</div>
          <div class="box-content1 bc_sign">——来自一位基层电商运营同学的心声</div>
        </div>
        <img
          class="ubl_image2" 
          src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/ds/2.png" width="67" height="200" />
      </div>
    </div>

    <div class="e_uper">
      <div class="eu_title fCenter">UiFox助力国内电商高速前行</div>
      <div class="eu_box fCenter">
        <div class="eub_item">
          <div class="eubi_num">60%</div>
          <div class="eubi_title">工作时间节省</div>
        </div>
        <div class="eub_item" style="margin-left:196px;margin-right:196px;">
          <div class="eubi_num">200%</div>
          <div class="eubi_title">买家评价获取效率提升</div>
        </div>
        <div class="eub_item">
          <div class="eubi_num">2000+</div>
          <div class="eubi_title">每天消息批量推送预计增量</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { Button } from "@/components";
import DownloadBtn from "../components/DownloadBtn.vue"

export default {
  name: "Electricity",
  components: {
    [Button.name]: Button,
    DownloadBtn,
  },
  data() {
    return {
      eleName: '1',
      dialog: false
    };
  },
  methods: {
    handleClose(){
      this.dialog = false
    },
		open(){
      this.$router.push({path:'/download'})
		},
  }
};
</script>

<style lang="scss" scoped>
.electricity-page {
    .page-header {
      height: 600px !important;
      background: url('../../assets/plan/s1_bg.png') no-repeat center/cover;
      margin-top: 60px;
      .ph_box {
        width: 1200px;
        height: 600px;
        margin: 0 auto;
        position: relative;
        z-index: 10;
        .pc_left {
          width: 600px;
          height: auto;
          flex-direction: column;
          align-items: flex-start;
          .pcl_title {
            width: 288px;
            height: 36px;
            font-size: 36px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #333333;
            line-height: 54px;
          }
          .pcl_desc {
            width: 581px;
            height: 68px;
            font-size: 20px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #333333;
            line-height: 34px;
            letter-spacing: 1px;
            margin-top: 24px;
            margin-bottom: 32px;
          }
        }
        .pc_right {
          width: 456px;
          height: 473px;
          background: url('../../assets/plan/in_right.png') no-repeat center/cover;
        }
      }
    }

  .first-div {
    width: 100%;
    height: 480px;
    background: #fff;
    padding: 0 80px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;

    display: flex;
    .left {
      .top {
        margin-top: 40px;
      }
      .bottom {
        width: 400px;
        margin: 100px 200px;
        // margin-top: -80px;
        .title {
          font-size: 28px;
          color: #353535;
          font-weight: bold;
          margin-bottom: 36px;
        }
        .content {
          margin-bottom: 20px;
          color: #87969d;
          font-size: 18px;
        }
        .contnet-box {
          width: 549px;
          height: 101px;
          padding: 24px 28px;
          box-shadow: 0px 0px 30px rgba($color: #000000, $alpha: 0.1);
          margin-bottom: 36px;
          position: relative;
          .line {
            width: 3px;
            height: 29px;
            background: #fc6002;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }
    .right {
    }
  }

  .e_uper {
    width: 100vw;
    height: 330px;
    background: url('../../assets/plan/in_bg.png') no-repeat center/cover;
    .eu_title {
      height: 120px;
      width: 100vw;
      font-size: 32px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
    }
    .eu_box {
      width: 100vw;
      height: auto;
    }
    .eub_item {
      display: flex;
      flex-direction: column;
      width: fit-content;
      height: auto;
      margin-top: -5px;
      .eubi_num {
        font-size: 80px;
        color: #fff;
        font-weight: bold;
      }
      .eubi_title {
        height: 16px;
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        letter-spacing: 1px;
      }
    }
  }

  .work-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    .text {
      font-size: 30px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #353535;
      line-height: 45px;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .box {
      display: flex;
    }

    .uifox-box-last {
      background: #fff;
      border-radius: 18px;
      box-shadow: 0px 4px 20px 4px rgba(0, 0, 0, 0.04);
      position: relative;

      .ubl_image1 {
        position: absolute;
        left: 16px;
        top: 10px;
      }
      .ubl_image2 {
        position: absolute;
        right: 10px;
        top: 75px;
      }

      .box-content1 {
        width: 1030px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #353535;
        line-height: 30px;
        margin-top: -20px;
      }
      .bc_sign {
        text-align: right;
        margin-top: 30px;
        font-weight: bold;
        color: #222222;
        line-height: 24px;
        letter-spacing: 1px;
        font-size: 16px;
      }
    }

    .uifox-box {
      width: 388px;
      height: 232px;
      background: #fff;
      box-shadow: 0px 4px 20px 4px rgba(0, 0, 0, 0.04);
      border-radius: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      .box-title1 {
        font-size: 20px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: bold;
        color: #333;
        margin-bottom: 15px;
      }
      .box-content {
        width: 300px;
        height: 70px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #666;
        line-height: 22px;
        text-align: center;
      }
    }
  }

  .h1-seo {
    font-size: 36px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    line-height: 75px;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }
  .h3-seo {
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    line-height: 30px;
    margin-bottom: 40px;
    width: 850px;
  }
  .download-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    width: 180px;
    height: 46px;
    background: linear-gradient(90deg, #fd621f 0%, #fb8a10 100%);
    box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.04);
    font-size: 16px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #ffffff;
    line-height: 24px;
    letter-spacing: 1px;
  }
  
}
</style>
