<template>
  <div class="version-page">
    <div class="vp_con">
      <div class="banner-back">
        <!-- <div class="banner-back-left"></div>
        <div class="banner-back-right"></div> -->
      </div>
      <div class="banner-con">
        <h1 class="h1-seo">定价和我们的产品一样简单</h1>
        <div class="h3-seo fCenter">{{ useTips }}</div>
      </div>
      <div class="content-box fCenter">
        <div
          @mousemove="changeTips(1)"
          @mouseout="changeTips(0)"
          class="content-div"
        >
          <div class="top she">
            <div class="top_icon ti_bg3"></div>
            <div class="top-title">社区版</div>
            <div class="top-text" style="padding: 12px 0">
              <p>
                适用于个人<span style="font-weight: bold">RPA</span
                >爱好者和初级开发者
              </p>
            </div>
            <div class="btns" @click="download(1)">免费获取</div>
          </div>
          <div class="titlev">免费</div>
          <div
            class="content"
            v-for="(item, index) in basisList"
            :key="`1-${index}`"
          >
            <div class="left">{{ item.left }}</div>
            <div class="right">{{ item.right }}</div>
          </div>
          <div class="titlev t_right">专属特权</div>
          <div
            class="content"
            v-for="(item, index) in basisList2"
            :key="`22-${index}`"
          >
            <div class="left">{{ item.left }}</div>
            <div class="right">{{ item.right }}</div>
          </div>
        </div>
        <div
          @mousemove="changeTips(2)"
          @mouseout="changeTips(0)"
          class="content-div"
          style="margin-left: 30px; margin-right: 30px"
        >
          <div class="top zhuan">
            <div class="top_icon ti_bg1"></div>
            <div class="top-title">专业版</div>
            <div class="top-text" style="padding: 12px 0">
              <p>
                面向<span style="font-weight: bold">RPA</span
                >专业开发者独立应用随处运行
              </p>
            </div>
            <!-- <div class="btns" @click="download(3)">免费获取</div> -->
            <div
              class="btns"
              onclick="window.open('https://wpa1.qq.com/yGWcFLfo?_type=wpa&qidian=true');"
            >
              联系我们
            </div>
          </div>
          <div class="titlev">特权付费</div>
          <div
            class="content"
            v-for="(item, index) in majorList"
            :key="`2-${index}`"
          >
            <div class="left">{{ item.left }}</div>
            <div class="right" style="color: #208bf7">{{ item.right }}</div>
          </div>
          <div class="titlev t_right">专属特权</div>
          <div
            class="content"
            v-for="(item, index) in majorList2"
            :key="`22-${index}`"
          >
            <div class="left">{{ item.left }}</div>
            <div class="right" style="color: #208bf7">{{ item.right }}</div>
          </div>
        </div>

        <div
          @mousemove="changeTips(3)"
          @mouseout="changeTips(0)"
          class="content-div"
        >
          <div class="top qiye">
            <div class="top_icon ti_bg2"></div>
            <div class="top-title">企业版</div>
            <div class="top-text">
              <p>大企业必备</p>
              <p>远程调度私域部署一对一服务</p>
            </div>
            <div
              class="btns"
              onclick="window.open('https://wpa1.qq.com/yGWcFLfo?_type=wpa&qidian=true');"
            >
              联系我们
            </div>
          </div>
          <div class="titlev">请联系我们</div>
          <div
            class="content"
            v-for="(item, index) in allList"
            :key="`3-${index}`"
          >
            <div class="left">{{ item.left }}</div>
            <div class="right" style="color: #c033fe">{{ item.right }}</div>
          </div>
          <div class="titlev t_right">专属特权</div>
          <div
            class="content"
            v-for="(item, index) in allList2"
            :key="`33-${index}`"
          >
            <div class="left">{{ item.left }}</div>
            <div class="right" style="color: #c033fe">{{ item.right }}</div>
          </div>
        </div>
      </div>
      <!-- 历史版本 -->
      <div class="history" v-if="historyData.length > 0">
        <div class="history-title">历史版本</div>
        <div class="history-box">
          <div class="history-list htitle">
            <div class="version">
              <img
                src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/common/images/h_version.png"
              />版本
            </div>
            <div class="time">
              <img
                src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/common/images/h_time.png"
              />发版时间
            </div>
            <div class="action">操作</div>
          </div>
          <div class="history-item">
            <div
              class="history-list"
              v-for="(item, index) in historyData"
              :key="index"
            >
              <div class="version">
                {{ clientTypeArr[item.clientType] }} {{ item.version }}
              </div>
              <div class="time">{{ item.updateTime }}</div>
              <div class="action">
                <a :href="item.filePath" target="_blank"
                  ><img
                    src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/common/images/h_download.png"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from "@/components";
import { help } from "../api/index";
import moment from "moment";

const clientTypeArr = {
  1: "社区版 (UiFox Community)",
  2: "运行器 (UiFox Robot)",
  3: "专业版 (UiFox Studio)",
};

export default {
  name: "version",
  bodyClass: "index-page",
  components: {
    [Button.name]: Button,
  },
  computed: {},
  data() {
    return {
      useTips: "只需为有价值的产品付费",
      dialog: false,
      basisList: [
        {
          left: "应用市场",
          right: "支持",
        },
        {
          left: "流程图",
          right: "支持",
        },
        {
          left: "可视化积木式编程",
          right: "支持",
        },
        {
          left: "低代码",
          right: "支持",
        },
        {
          left: "智能录制",
          right: "支持",
        },
        {
          left: "手机自动化",
          right: "支持",
        },
      ],
      basisList2: [
        {
          left: "发布到应用市场",
          right: "支持",
        },
        {
          left: "云端同步",
          right: "支持",
        },
      ],
      majorList: [
        {
          left: "流程图",
          right: "支持",
        },
        {
          left: "可视化积木式编程",
          right: "支持",
        },
        {
          left: "低代码",
          right: "支持",
        },
        {
          left: "元素库",
          right: "支持",
        },
        {
          left: "自定义人机交互界面",
          right: "支持",
        },
        {
          left: "代码版本管理",
          right: "支持",
        },
        {
          left: "智能录制",
          right: "支持",
        },
        {
          left: "桌面分身",
          right: "支持",
        },
        {
          left: "编程开发助手",
          right: "支持",
        },
        {
          left: "手机自动化",
          right: "敬请期待",
        },
      ],
      majorList2: [
        {
          left: "打包独立机器人",
          right: "支持",
        },
        {
          left: "云端同步",
          right: "支持",
        },
        {
          left: "远程调试",
          right: "支持",
        },
        {
          left: "远程调度管理",
          right: "支持",
        },
        {
          left: "企业需求定制",
          right: "支持",
        },
        {
          left: "在线技术服务支持",
          right: "支持",
        },
        {
          left: "支持开发自动化解决方案",
          right: "支持",
        },
        {
          left: "定制化部署",
          right: "支持",
        },
      ],
      runList: [
        {
          left: "应用市场",
          right: "支持",
        },
        {
          left: "本地计划任务",
          right: "支持",
        },
        {
          left: "远端计划任务",
          right: "支持",
        },
        {
          left: "桌面分身",
          right: "支持",
        },
        {
          left: "PC自动化",
          right: "支持",
        },
        {
          left: "手机自动化",
          right: "敬请期待",
        },
      ],
      runList2: [
        {
          left: "企业管理中心",
          right: "支持",
        },
        {
          left: "远程调度管理",
          right: "支持",
        },
        {
          left: "企业需求定制",
          right: "支持",
        },
        {
          left: "一对一技术服务支持",
          right: "支持",
        },
        {
          left: "定制化部署",
          right: "支持",
        },
      ],
      allList: [
        {
          left: "云端计划任务",
          right: "支持",
        },
        {
          left: "流程图",
          right: "支持",
        },
        {
          left: "可视化积木式编程",
          right: "支持",
        },
        {
          left: "低代码",
          right: "支持",
        },
        {
          left: "元素库",
          right: "支持",
        },
        {
          left: "自定义人机交互界面",
          right: "支持",
        },
        {
          left: "代码版本管理",
          right: "支持",
        },
        {
          left: "智能录制",
          right: "支持",
        },
        {
          left: "桌面分身",
          right: "支持",
        },
        {
          left: "手机自动化",
          right: "敬请期待",
        },
      ],
      allList2: [
        {
          left: "发布到应用市场",
          right: "支持",
        },
        {
          left: "专属应用市场",
          right: "支持",
        },
        {
          left: "专属资源市场",
          right: "支持",
        },
        {
          left: "专属云端同步",
          right: "支持",
        },
        {
          left: "企业管理中心",
          right: "支持",
        },
        {
          left: "远程调度管理",
          right: "支持",
        },
        {
          left: "企业需求定制",
          right: "支持",
        },
        {
          left: "一对一技术服务支持",
          right: "支持",
        },
        {
          left: "支持自动化解决方案",
          right: "支持",
        },
        {
          left: "私有化部署",
          right: "支持",
        },
      ],

      clientTypeArr,
      historyData: [],
    };
  },
  created() {
    help.getHistory().then((res) => {
      const datasource = res.data.data;
      datasource.map((d) => {
        console.log(d);
        d.updateTime = moment(d.updateTime).format("YYYY-MM-DD");
      });
      this.historyData = datasource;
    });
  },
  methods: {
    changeTips(v) {
      let useText = {
        0: "只需为有价值的产品付费",
        1: "适用于个人RPA爱好者和初级开发者",
        2: "面向RPA专业开发者独立应用随处运行",
        3: "大企业必备 远程调度私域部署一对一服务",
      };
      this.useTips = useText[v];
    },
    // 关闭弹窗
    handleClose() {
      this.dialog = false;
    },
    open() {
      this.$router.push({ path: "/download" });
    },
    // 跳转 联系我们
    goContact() {
      this.$router.push({ path: "/contact/contact_us" });
    },
    download(type) {
      if (type == 1 || type == 3) {
        help.getUrl(type).then((res) => {
          window.open(res.data.data, "_target");
        });
        this.$router.push({ path: "/download" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.version-page {
  .pr_contactus {
    background: #fff;
    padding-top: 100px;
    .prc_title {
      width: 324px;
      height: 36px;
      font-size: 36px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #333333;
      line-height: 54px;
      margin: 0 auto;
      margin-bottom: 60px;
    }
  }
  .vp_con {
    width: 100vw;
    height: auto;
    background: #000000;
    margin-top: 60px;
    .banner-back {
      display: block;
      background-image: url("../assets/version2/pr-img.png");
      height: 570px;
      .banner-back-left {
        background: none;
        background-image: url("../assets/version2/pr-img-left.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: top center;
        float: left;
        width: 631px;
        height: 445px;
        margin: 125px 0 0 140px;
      }
      .banner-back-right {
        background: none;
        background-image: url("../assets/version2/pr-img-right.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: top center;
        float: right;
        width: 542px;
        height: 235px;
        margin: 120px 80px 0 0;
      }
    }
  }
  .banner-con {
    width: 100vw;
    height: 570px;
    position: relative;
    z-index: 2;
    //background: url("../assets/about/pr_bg.png") no-repeat center/cover;
    padding-top: 140px;
    .h1-seo {
      width: 100%;
      font-weight: bold !important;
      font-size: 56px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: normal;
      color: #fff;
      text-align: center;
    }
    .dwn_tips {
      width: 832px;
      height: 16px;
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      line-height: 24px;
      margin: 0 auto;
    }
    .h3-seo {
      margin: 20px 0 30px 0;
      width: 465px;
      height: 36px;
      background: #fff;
      border-radius: 18px;
      font-size: 20px;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: #ff7f00;
      margin: 0 auto;
      margin-top: 10px;
    }
    .dwn_wx {
      width: 580px;
      height: 186px;
      border-radius: 8px;
      background: #fff;
      margin: 60px auto 0;
      .dw_left {
        width: 158px;
        height: 158px;
        background: url("../assets/about/code.png") no-repeat center/contain;
        margin-left: 24px;
      }
      .dw_right {
        flex: auto;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 24px;
        .dri_t {
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #333333;
          line-height: 24px;
        }
        .dri_logo {
          width: 28px;
          height: 28px;
          background: url("../assets/about/wx.png") no-repeat center/contain;
          margin-right: 8px;
        }
        .dri_box {
          width: 28px;
          height: 28px;
          margin-right: 8px;
          .dri_icon {
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background: #000;
          }
        }
      }
    }
  }
  .content-box {
    display: flex;
    position: relative;
    margin-top: -160px;
    padding-bottom: 100px;
    z-index: 99;
  }
  .content-div {
    width: 380px;
    height: 1220px;
    border-radius: 12px;
    position: relative;
    background: #fff;
    z-index: 0;
    .top_icon {
      width: 123px;
      height: 88px;
      position: absolute;
      left: 0;
      top: 0;
    }
    .ti_bg1 {
      background: url("../assets/about/pr_s1.png") no-repeat center/contain;
    }
    .ti_bg2 {
      background: url("../assets/about/pr_s2.png") no-repeat center/contain;
    }
    .ti_bg3 {
      background: url("../assets/about/pr_s3.png") no-repeat center/contain;
    }
    .top {
      width: 100%;
      height: 204px;
      position: relative;
      text-align: center;
      color: #ffffff;
      overflow: hidden;

      .btns {
        width: 120px;
        height: 42px;
        line-height: 42px;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        margin: 12px auto;
        border-radius: 4px;
        cursor: pointer;

        &.disabled {
          cursor: default;
        }
      }

      &.yun {
        background: url("https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/guodu/back3.png")
          no-repeat;
        background-size: 100%;

        .btns {
          background-size: 100%;
          background: linear-gradient(#e2b7fd, #c054fb);
        }
      }

      &.she {
        background: url("../assets/about/pr_t1.png") no-repeat;
        background-size: 100%;

        .btns {
          background-size: 100%;
          background: linear-gradient(#fbcaab, #f97d31);
        }
      }

      &.zhuan {
        background: url("../assets/about/pr_t2.png") no-repeat;
        background-size: 100%;

        .btns {
          background-size: 100%;
          background: linear-gradient(#97cdfb, #3997f7);
        }
      }

      &.qiye {
        background: url("../assets/about/pr_t3.png") no-repeat;
        background-size: 100%;

        .btns {
          background-size: 100%;
          background: linear-gradient(#e2b7fd, #c054fb);
        }
      }

      .top-title {
        font-size: 32px;
        font-weight: bold;
        line-height: 40px;
        padding-top: 28px;
        color: #fdfdfd;
      }

      .top-text {
        margin-top: 10px;
        p {
          font-size: 18px;
          font-weight: 500;
          line-height: 25px;
          margin: 0;
        }
      }
    }

    .titlev {
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 18px;
      font-weight: bold;
      color: #222;
    }

    .t_right {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
    }

    .content {
      display: flex;
      justify-content: space-between;
      margin: 0 16px 16px 16px;

      .left {
        color: #666;
        font-size: 18px;
      }

      .right {
        color: #f87f34;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
  .dowl_btn {
    color: #fff;
    font-size: 18px;
    border: 1px solid #fff;
    border-radius: 4px;
    width: 158px;
    height: 46px;
    cursor: pointer;
  }

  .history {
    padding-bottom: 100px;
    img {
      width: 18px;
      height: 18px;
      vertical-align: -2px;
      margin-right: 3px;
    }
    .history-title {
      text-align: center;
      height: 52px;
      font-size: 52px;
      font-weight: bold;
      color: #ffffff;
      line-height: 52px;
      letter-spacing: 2px;
    }

    .history-box {
      width: 1200px;
      background: #ffffff;
      border-radius: 12px;
      margin: 60px auto 0;

      .history-list {
        display: flex;
        flex-direction: row;
        border-top: 1px solid #dedede;
        height: 82px;
        line-height: 82px;
        margin: 0 50px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        letter-spacing: 2px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;

        &:nth-child(1) {
          border: 0;
        }

        .version {
          flex: 1;
          padding: 0 24px;
        }
        .time {
          width: 360px;
          padding: 0 24px;
        }
        .action {
          width: 90px;
          padding: 0 24px;

          img {
            cursor: pointer;
          }
        }

        &.htitle {
          font-size: 18px;
          letter-spacing: 2px;
          font-weight: bold;
          color: #333333;
          border-bottom: 1px solid #dedede;
        }
      }
    }
  }
}
</style>
