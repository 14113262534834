<template>
  <div class="product-page">
    <!-- <div class="page-header" filter-color="orange" style="background-image:url('https://uifox-public.oss-cn-hangzhou.aliyuncs.com/uifox-files/mainpic/ux/banner.png'); background-repeat: no-repeat; background-size: auto 100%; background-position: top center;"> -->
    <div class="page-header">
      <parallax class="page-header-image">
        <!-- style="background-image:url('img/header.jpg')" -->
      </parallax>
      <div class="container" style="padding-top: 150px;">
        <div class="content-left brand">
          <div class="c-l-text">
            <h1 class="h1-seo">解放双手就现在！</h1>
            <div class="h3-seo">RPA (Robotic Process Automation) 是一款智能化的流程编排、流程执行和流程管控工具，<br />采用“非侵入”和低代码方式完成业务流程的全链路、全场景和全自动化执行。</div>
            <n-button type="primary" class="download-btn" @click="open">免费试用</n-button>
          </div>
        </div>
      </div>
    </div>

    <div class="banner-back"></div>
    <div style="height: 520px;"></div>

    <div class="work-div wow bounceInUp" data-wow-duration="1.5s" data-wow-delay="0.2s" data-wow-offset="200" style="height: 482px;">
      <div class="text">
        RPA机器人的工作方式
        <!-- <div style="width: 113px;height:7px;border-radius: 2px;background:#FC6002;"></div> -->
      </div>
      <img class="image" src="https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/product/work.png" width="1300" height="230" />
    </div>

    <div class="work-div wow bounceInUp" data-wow-duration="1.5s" data-wow-delay="0.2s" data-wow-offset="200" style="height: 840px;background: #F7F7F7;">
      <div class="text">
        UiFox机器人的优势
        <!-- <div style="width: 113px;height:7px;border-radius: 2px;background:#FC6002;margin-bottom: 70px;"></div> -->
      </div>
      <div class="box" style="margin-top: 80px;">
        <CardCell class="wow bounceInUp" data-wow-duration="1.5s" data-wow-delay="0.2s" data-wow-offset="200" url="1" title="非侵入式交互" content="将流程机器人部署到IT环境中，不影响现有IT系统的稳定性，同时这种灵活的特点可以广泛应用到没有API接口的场合。" style="margin: 10px;" />
        <CardCell class="wow bounceInUp" data-wow-duration="1.5s" data-wow-delay="0.2s" data-wow-offset="200" url="2" title="可视化搭建流程" content="您只需要向搭积木似的简单拖拉组合即可，一目了然的可视化搭建，即使您不会编写代码，也可轻松使用。" style="margin: 10px;" />
      </div>
      <div class="box">
        <CardCell class="wow bounceInUp" data-wow-duration="1.5s" data-wow-delay="0.2s" data-wow-offset="200" url="3" title="灵活的部署方式" content="除了将机器人部署在服务端外，还可以将机器人部署在云端。" style="margin: 10px;" />
        <CardCell class="wow bounceInUp" data-wow-duration="1.5s" data-wow-delay="0.2s" data-wow-offset="200" url="4" title="7*24小时工作" content="RPA机器人可以全天候不间断执行任务，极大的提高了工作的效率。" style="margin: 10px;" />
      </div>
      <div class="box">
        <CardCell class="wow bounceInUp" data-wow-duration="1.5s" data-wow-delay="0.2s" data-wow-offset="200" url="5" title="投入产出比高" content="RPA机器人替代您处理大批量重复的、繁琐的流程工作，减少您的工作时长。" style="margin: 10px;" />
        <CardCell class="wow bounceInUp" data-wow-duration="1.5s" data-wow-delay="0.2s" data-wow-offset="200" url="6" title="更准确的操作" content="RPA机器人是固定化的程序，模拟人工操作，在工作过程中不会像人为操作那样因主观因素犯错，更加有序可靠。" style="margin: 10px;" />
      </div>
    </div>

    <div class="work-div wow bounceInUp" data-wow-duration="1.5s" data-wow-delay="0.2s" data-wow-offset="200" style="height: 1000px;">
      <div class="text" style="margin: 70px 0;">
        RPA适用场景
      </div>
      <div class="box">
        <CardColumn url="1" title="国际物流" content="物流库存一般需要操作人员实时监控和维护，确保有足够库存不会因缺货而产生问题。RPA机器人可以实时监控库存水平。当库存较低时，RPA能及时通知相关人员进行补货、采购，并提供实时报告以优化库存需求。" style="margin: 15px;" />
        <CardColumn url="2" title="电商" content="订单管理、地址修改、库存录入、活动报名、广告复核等众多日常流程中，存在大量机械单调重复的操作，这些逻辑清晰、步骤明确的操作完全可以通过RPA机器人来自动处理，避免人为操作失误的同时又能提升处理效率。" style="margin: 15px;" />
        <CardColumn url="3" title="跨境电商" content="在跨境电商工作场景中有许多重复、繁琐的工作，处理这些工作大量时间。比如采购商品时，需要将商品到1688平台里面逐一采购，采购完成后还需在系统里记录，过程繁琐冗长，需要花费大量时间，用RPA机器人，可自动采购，极大的增加效率。" style="margin: 15px;" />
        <CardColumn url="4" title="新零售" content="活动促销作为新零售行业的一大支出项，该流程涉及大量手动操作，同时基于电子表格，需要持续的数据收集和分析。RPA机器人可以实现自动抓取和收集数据，避免人为操作错误的同时提高效率。" style="margin: 15px;" />
      </div>
      <div class="box">
        <CardColumn url="5" title="财务" content="财务日常工作中必然会频繁操作各类表单，诸如企业对账、会计制单、报销处理、自动报账、流程审批等场景中，往往需要根据特定的财务核算逻辑来人工处理大量财务单据，使用RPA机器人可自动批量完成同样的操作。" style="margin: 15px;" />
        <CardColumn url="6" title="制造" content="物料清单是制造业中至关重要的数据文件，包含了产品各种详细信息。人工处理时，需要填写的产品信息繁杂，极易出现错误，用RPA可自动创建并更新物料清单可有效避免人为错误，同时增加效率。" style="margin: 15px;" />
        <CardColumn url="7" title="金融" content="业务清算、数据抓取、数据查询、报表下载、发票调整等金融日常流程，需要投入较多精力人工操作电子表格和网站表单，使用RPA机器人，可以按照指定的逻辑，自动快捷地处理同样的工作，提升日常工作效率。" style="margin: 15px;" />
        <CardColumn url="8" title="货代" content="货运代理在日常业务中，会频繁操作船司系统和自有系统，其中舱单发送、VGM发送、AMS填报、自动订舱、数据整理等业务，通常都涉及一套固定的操作流程，使用RPA机器人，能够自动精准执行这些操作。" style="margin: 15px;" />
      </div>
    </div>

    <LastDownload bg="1" />
    <PopupCode :dialogType="dialog" @close="handleClose" />

  </div>
</template>

<script>
import { Button } from "@/components";
import PopupCode from "../components/PopupCode/PopupCode.vue"
import CardCell from "./components/CardCell.vue"
import CardColumn from "./components/CardColumn.vue"
import LastDownload from "./components/LastDownload.vue"
import { WOW } from "wowjs";

export default {
  name: "index",
  bodyClass: "index-page",
  components: {
    [Button.name]: Button,
    PopupCode,
    CardCell,
    CardColumn,
    LastDownload
  },
  computed: {},
  data() {
    return {
      showBack: false,
      dialog: false,
      basisList: [{
        left: '使用人数',
        right: '1人'
      },
      {
        left: '运行器(Robot)+设计器(studio)',
        right: '支持'
      },
      {
        left: '应用市场',
        right: '部分支持'
      },
      {
        left: 'PC自动化',
        right: '支持'
      },
      {
        left: '手动自动化',
        right: '敬请期待'
      },
      {
        left: '本地计划任务',
        right: '支持'
      },]
    };
  },
  created() {},
  mounted() {
    var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 10,
      mobile: true,
      live: true
    })
    wow.init()
  },
  methods: {
    // 关闭弹窗
    handleClose(){
      this.dialog = false
    },
    open(){
      this.$router.push({path:'/download'})

      // this.dialog = true
    }
  },
};
</script>

<style lang="scss" scoped>
.product-page {
  .page-header {
    height: 520px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  }
  .banner-back {
    height: 520px !important;
    background: url("https://uifox-public.oss-cn-hangzhou.aliyuncs.com/home/image/product/bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // background-size: cover;
    background-position: top center;
  }

  .first-div {
    width: 100%;
    height: 480px;
    background: #fff;
    padding: 0 80px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;

    display: flex;
    .left {
      .top {
        margin-top: 40px;
      }
      .bottom {
        width: 400px;
        margin: 100px;
        // margin-top: -80px;
        .title {
          font-size: 28px;
          color: #353535;
          font-weight: bold;
          margin-bottom: 16px;
        }
        .content {
          margin-bottom: 20px;
          color: #87969d;
          font-size: 18px;
        }
      }
    }
    .right {
    }
  }

  .work-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    .image {
      margin-top: 60px;
    }

    .text {
      font-size: 32px;
      color: #353535;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .box {
      display: flex;
    }

    .uifox-box {
      width: 360px;
      height: 360px;
      background: #fff;
      box-shadow: 10px 10px 40px rgba($color: #000000, $alpha: 0.1);
      padding: 40px;
      margin: 0 40px 40px 0;
      .box-title {
        font-size: 24px;
        font-weight: bold;
        margin: 25px 0;
        color: #222;
      }
      .box-content {
        font-size: 18px;
        color: #999;
        height: 103px;
        overflow: hidden;
      }
    }

    .box-rpa {
      display: flex;
      .uifox-box-rpa {
        width: 500px;
        height: 400px;
        // background: #fff;
        // box-shadow: 10px 10px 40px rgba($color: #000000, $alpha: 0.1);
        padding: 40px;
        margin: 0 40px 40px 0;
        .box-title {
          font-size: 24px;
          font-weight: bold;
          margin: 25px 0;
          color: #222;
        }
        .box-content {
          font-size: 18px;
          color: #999;
          // height: 103px;
          // overflow: hidden;
        }
      }
    }
  }

  .h1-seo {
    margin-bottom: 8px;
    font-weight: bold !important;
    font-size: 50px;
    color: #fff;
  }
  .h3-seo {
    margin-top: 20px;
    font-size: 16px;
    width: 579px;
    line-height: 32px;
    font-weight: 500;
    color: #fff;
  }

  .download-btn {
    background: linear-gradient(90deg, #fd621f 0%, #fb8a10 100%);
    box-shadow: none !important;
    border: none;
    font-size: 16px;
    font-weight: bold !important;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN !important;
    &:hover {
      color: #ff6600 !important;
      background: #fff !important;
    }
  }
}
</style>
