import Vue from "vue";
import Router from "vue-router";
import Index from "./pages/Index.vue";
import Help from "./pages/help/Help.vue";
import HelpDetail from "./pages/help/HelpDetail.vue";
import ClientHelp from "./pages/help/ClientHelp.vue";
import ClientDoc from "./pages/help/ClientDoc.vue";

import about from "./pages/about/about.vue";
import contact from "./pages/contact/contact.vue";
import version from "./pages/version.vue";
import download from "./pages/download.vue";
import customer from "./pages/customer.vue";
import product from "./pages/product.vue";
import logistics from "./pages/plan/logistics.vue";
import electricity from "./pages/plan/electricity.vue";
import crossElectricity from "./pages/plan/crossElectricity.vue";

import Detail from "./pages/help/Detail.vue";
import Landing from "./pages/Landing.vue";
import Login from "./pages/Login.vue";
import Profile from "./pages/Profile.vue";
import MainNavbar from "./layout/MainNavbar.vue";
import MainFooter from "./layout/MainFooter.vue";
import SuccessCase from "./pages/SuccessCase.vue";

Vue.use(Router);

export default new Router({
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "index",
      components: { default: Index, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/index",
      name: "index",
      components: { default: Index, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/successCase",
      name: "successCase",
      components: {
        default: SuccessCase,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/solutions/local_e-commerce",
      name: "electricity",
      components: {
        default: electricity,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400, headerType: "white" },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/solutions/cross-border_e-commerce",
      name: "crossElectricity",
      components: {
        default: crossElectricity,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400, headerType: "white" },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/solutions/international_logistics",
      name: "logistics",
      components: {
        default: logistics,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400, headerType: "white" },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/contact/contact_us",
      name: "contact",
      components: { default: contact, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/version",
      name: "version",
      components: { default: version, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/download",
      name: "download",
      components: { default: download, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/product",
      name: "product",
      components: { default: version, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/customer",
      name: "customer",
      components: { default: customer, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/about/about_us",
      name: "about",
      components: { default: about, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
      beforeRouteEnter: () => {
        // console.log('sadffffff')
      },
    },
    {
      path: "/help/detail",
      name: "helpDetail",
      components: { default: Detail, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/help/Help",
      name: "help",
      components: { default: Help, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/help/HelpDetail",
      //name: 'help',
      name: "helpdetail",
      components: {
        default: HelpDetail,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },

    {
      path: "/help/ClientHelp",
      name: "ClientHelp",
      components: { default: ClientHelp },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/help/ClientDoc",
      name: "ClientDoc",
      components: { default: ClientDoc },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/landing",
      name: "landing",
      components: { default: Landing, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/login",
      name: "login",
      components: { default: Login, header: MainNavbar },
      props: {
        header: { colorOnScroll: 400 },
      },
    },
    {
      path: "/profile",
      name: "profile",
      components: { default: Profile, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
  ],
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});
